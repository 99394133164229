import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  makeStyles,
  Typography,
  Grid,
  Button,
  useTheme,
  useMediaQuery,
  colors,
  CircularProgress,
  IconButton
} from '@material-ui/core';
import Event from './Event';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import {
  // ArrowBackIosRounded,
  // ArrowForwardIosRounded,
  ArrowBackRounded,
  ArrowForwardRounded,
  InfoOutlined
} from '@material-ui/icons';
import { v4 as uuid } from 'uuid';
import clsx from 'clsx';

export default function GameSelector({
  events,
  handleEventClick,
  activeEventIndex,
  eventCodeDetail,
  apiRequestInProgress,
  setActiveEventIndex,
  setEventCodeDetail,
  eventsLoading
}) {
  const styles = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const md = useMediaQuery(theme.breakpoints.down('md'));
  const [swiper, setSwiper] = useState(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(true);

  const slideNext = () => swiper.slideNext();
  const slidePrev = () => swiper.slidePrev();

  const handleSwiperInit = swiper => {
    setSwiper(swiper);
    setIsBeginning(swiper.isBeginning);
    setIsEnd(swiper.isEnd);
  };

  const changeNavButtonStatus = swiper => {
    setIsBeginning(swiper.isBeginning);
    setIsEnd(swiper.isEnd);
  };

  const handleSlideTap = swiper => {
    let slide = swiper.clickedSlide?.querySelector('.event_slide');
    if (slide !== undefined) {
      let eventCode = slide.getAttribute('data-event_code');
      let eventIndex = slide.getAttribute('data-event_index');
      if (eventCode == eventCodeDetail) return;
      handleEventClick(eventCode, eventIndex);
    }
  };

  return (
    <Paper variant="outlined">
      <Box p={3} pb={0}>
        <Box textAlign="center">
          <Typography
            variant="h5"
            color="textPrimary"
            className={styles.sectionTitle}
          >
            What game do you want to attend ?
          </Typography>
        </Box>

        <Box mt={1} textAlign="center">
          <Typography variant="subtitle1" className={styles.sectionSubtitle}>
            <InfoOutlined />
            Swipe to see more events, tap to select one.
          </Typography>
        </Box>
      </Box>
      <Box pb={3}>
        {events.length === 0 ? (
          <Box textAlign="center" mt={3}>
            <CircularProgress size={24} color="primary" />
          </Box>
        ) : (
          <>
            <Box className={styles.eventSliderWrapper}>
              <Box p={1}>
                <IconButton
                  disableRipple
                  disabled={isBeginning}
                  className={clsx(styles.slideNavButton, styles.navButtonPrev)}
                  onClick={slidePrev}
                >
                  <ArrowBackRounded className={styles.buttonIcon} />
                </IconButton>
              </Box>

              {eventsLoading && (
                <Box className={styles.loaderOverlay}>
                  <CircularProgress size={28} color="primary" />
                </Box>
              )}

              <Swiper
                onInit={handleSwiperInit}
                onSlideChange={changeNavButtonStatus}
                pagination={{
                  el: '.event_slider_pagination',
                  type: 'bullets'
                }}
                slideToClickedSlide={true}
                centeredSlides={
                  sm ? true : activeEventIndex == 0 ? false : true
                }
                className={styles.swiperParent}
                spaceBetween={15}
                slidesPerView={xs ? 1.25 : sm ? 1.5 : md ? 2.5 : 3.5}
                // centeredSlidesBounds={
                //   sm ? false : activeEventIndex == 0 ? true : false
                // }
                onClick={handleSlideTap}
              >
                {events.map((event, index) => (
                  <SwiperSlide className={styles.swiperSlide} key={uuid()}>
                    <Paper
                      elevation={6}
                      variant="outlined"
                      className={clsx(
                        'event_slide',
                        styles.eventPaper,
                        eventCodeDetail == event.event_code
                          ? styles.eventActive
                          : ''
                      )}
                      data-event_index={index}
                      data-event_code={event.event_code}
                    >
                      <Event
                        {...{
                          event,
                          index,
                          handleEventClick,
                          activeEventIndex,
                          eventCodeDetail
                        }}
                      />
                    </Paper>
                  </SwiperSlide>
                ))}
              </Swiper>

              <Box p={1}>
                <IconButton
                  disableRipple
                  disabled={isEnd}
                  className={clsx(styles.slideNavButton, styles.navButtonNext)}
                  onClick={slideNext}
                >
                  <ArrowForwardRounded className={styles.buttonIcon} />
                </IconButton>
              </Box>
            </Box>
            <Box className="event_slider_pagination"></Box>
          </>
        )}
      </Box>
    </Paper>
  );
}

const useStyles = makeStyles(theme => ({
  eventPaper: {
    cursor: 'pointer',
    height: '100%',
    transition: 'transform 0.3s ease',
    boxShadow: '0 8px 16px rgb(0 0 0 / 10%)',
    borderRadius: 8,
    position: 'relative',
    overflow: 'hidden',

    '&:hover': {
      transform: 'translateY(-5px)'
    },

    '&:active': {
      border: `2px solid ${theme.palette.primary.main}`
    }
  },
  eventActive: {
    border: `2px solid ${theme.palette.primary.main}`,
    transform: 'translateY(-5px)'
  },

  eventClicked: {
    border: `2px solid ${theme.palette.primary.main}`,
    transform: 'translateY(-5px)'
  },
  sectionTitle: {
    fontFamily: 'Raleway',
    fontSize: 24,
    fontWeight: 700
  },

  sectionSubtitle: {
    fontFamily: 'Open Sans',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.2,
    color: theme.palette.success.main,

    '& .MuiSvgIcon-root': {
      marginBottom: -4,
      marginRight: 5,
      fontSize: 20
    }
  },
  eventSliderWrapper: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative'
  },

  swiperParent: {
    '& .swiper-wrapper': {
      paddingTop: 40,
      paddingBottom: 40
    }
  },
  swiperSlide: {
    height: 'unset',
    minWidth: 200

    // display: 'table-cell'
  },
  slideNavButton: {
    border: `2px solid ${colors.grey[600]}`,
    width: 40,
    height: 40,
    transition: 'all 0.2s ease',
    backgroundColor: colors.grey[300],

    [theme.breakpoints.down('xs')]: {
      width: 25,
      height: 25
    },

    '&.Mui-disabled': {
      opacity: 0.3
    },

    '& .MuiSvgIcon-root': {
      color: colors.grey[600],
      transition: 'all 0.2s ease'
    },

    '&:hover': {
      border: `2px solid ${theme.palette.primary.main}`,

      '& .MuiSvgIcon-root': {
        color: theme.palette.primary.main
      }
    }
  },

  navButtonNext: {
    '&:hover .MuiSvgIcon-root': {
      transform: 'translateX(3px)',

      [theme.breakpoints.down('md')]: {
        transform: 'none'
      }
    }
  },
  navButtonPrev: {
    '&:hover .MuiSvgIcon-root': {
      transform: 'translateX(-3px)',
      [theme.breakpoints.down('md')]: {
        transform: 'none'
      }
    }
  },
  loaderOverlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 11,
    backgroundColor: 'rgba(255,255,255,0.8)'
  }
}));
