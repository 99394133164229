import React from 'react';
import {
  Box,
  Paper,
  Avatar,
  Typography,
  makeStyles,
  colors
} from '@material-ui/core';
import { ConfirmationNumberOutlined } from '@material-ui/icons';

export default function SeatInfo({
  seat,
  gameDate,
  gameTime,
  seatString,
  gameImage
}) {
  const styles = useStyles();
  return (
    <Paper variant="outlined">
      <Box p={1} className={styles.seatWrapper}>
        <Box>
          {gameImage ? (
            <img
              src={gameImage}
              alt="game-image"
              className={styles.gameImage}
            />
          ) : (
            <Avatar variant="rounded" className={styles.avatar}>
              <ConfirmationNumberOutlined className={styles.orderIcon} />
            </Avatar>
          )}
        </Box>

        <Box>
          <Box>
            <Typography
              variant="h6"
              color="textPrimary"
              className={styles.seatName}
            >
              Section {seat.section_name}
              <span className={styles.seatNameDivider} />
              Row {seat.row_num}
            </Typography>
          </Box>

          <Box>
            <Typography variant="subtitle2" className={styles.seatMeta}>
              {seatString}
            </Typography>
          </Box>

          <Box>
            <Typography variant="subtitle2" className={styles.seatMeta}>
              {gameDate}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2" className={styles.seatMeta}>
              {gameTime}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}

const useStyles = makeStyles(theme => ({
  seatWrapper: {
    display: 'grid',
    gridTemplateColumns: '45px 1fr',
    alignItems: 'flex-start'
  },
  gameImage: {
    width: 40,
    marginTop: 4
  },
  avatar: {
    backgroundColor: 'transparent',
    border: `1px solid ${colors.grey[400]}`,
    width: 32,
    height: 32,
    marginTop: 4
  },
  orderIcon: {
    color: colors.grey[600],
    fontSize: 24
  },
  seatName: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 16,
    lineHeight: 1.2
  },

  seatNameDivider: {
    height: 20,
    marginLeft: 5,
    marginRight: 5,
    width: 2,
    backgroundColor: theme.palette.divider,
    display: 'inline-block',
    marginBottom: -3
  },

  seatMeta: {
    fontFamily: 'Open Sans',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.4,
    color: colors.grey[600],

    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  }
}));
