import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useCookies } from 'react-cookie';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [cookies] = useCookies(['accessToken']);
  let accesstToken = localStorage.getItem('accessToken');

  return (
    <Route
      {...rest}
      render={props =>
        cookies.accessToken && accesstToken ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default PrivateRoute;
