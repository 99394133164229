import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactChild
} from 'react';
import { siteTitle } from 'src/views/data/environment';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { WHITE_LABEL_TEAM_ID } from 'src/views/data/environment';

declare global {
  interface Window {
    $crisp: Record<string, any>;
  }
}

interface PropTypes {
  children: ReactChild;
}

type CrispType = Record<string, any> | null;

const CrispContext = createContext<CrispType>(null);

export const CrispProvider = ({ children }: PropTypes) => {
  const location = useLocation();
  const state = useSelector((state: Record<string, any>) => state);
  const [crisp, setCrisp] = useState<CrispType>(null);
  const [teamId, setTeamId] = useState<number>();
  const [accountId, setAccountId] = useState<string>();
  const [emailAddress, setEmailAddress] = useState<string>();
  const [customerCredit, setCustomerCredit] = useState<string>();

  // Helper fn to push data to crisp
  const crispPush = (key: string, value: string | number) =>
    crisp && crisp.push(['set', 'session:data', [[[key, value]]]]);

  // Pushing data to crisp, based on states
  const setCustomProperties = () => {
    crisp && crispPush('site_name', `${siteTitle}--FLEX`);
    crisp && accountId && crispPush('user_account_id', accountId);
    crisp && customerCredit && crispPush('user_credit', customerCredit);
    crisp && emailAddress && crisp.push(['set', 'user:email', [emailAddress]]);
    crisp && teamId && crispPush('team_id', teamId);
  };

  // setting crisp to the state
  useEffect(() => {
    setCrisp(window.$crisp);
  }, [window.$crisp]);

  // Setting user credit and accountId to the state
  useEffect(() => {
    const customerCredit =
      state.customers?.customerCredits?.creditAvailable?.$numberDecimal;
    const accountId = state.customers?.customerCredits?.accountNumber;
    const emailAddress = state.customers?.customerCredits?.emailAddress;
    setTeamId(WHITE_LABEL_TEAM_ID);
    setEmailAddress(emailAddress);
    setAccountId(accountId);
    setCustomerCredit(customerCredit);
  }, [state]);

  // Running the push data fn
  useEffect(setCustomProperties, [crisp, state]);

  // Hiding the chatbox on portal
  useEffect(() => {
    if (location.pathname.indexOf('portal') > 0) {
      crisp && crisp.push(['do', 'chat:hide']);
    } else {
      crisp && crisp.push(['do', 'chat:show']);
    }
  });

  return (
    <CrispContext.Provider value={crisp}>{children}</CrispContext.Provider>
  );
};
