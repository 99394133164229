import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Dialog,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  OutlinedInput,
  Box,
  Button,
  DialogTitle,
  Divider,
  Typography,
  CircularProgress,
  makeStyles,
  colors
} from '@material-ui/core';
import { DateRangeOutlined } from '@material-ui/icons';
import axios from 'axios';
import { API_BASE_URL } from '../../data/environment';
import * as eventActions from '../../../state/event/eventAction';
import { toast } from 'react-toastify';
import { logourldata } from './logourldata';
import csvtojson from 'csvtojson';
import CreatableSelect from 'react-select/creatable';
import { option } from 'yargs';

const useStyles = makeStyles(theme => ({
  root: {},
  inputLabel: {
    backgroundColor: 'white !important'
  },
  logoSelectLabel: {
    fontSize: 12,
    marginLeft: 15,
    marginBottom: 3
  },
  optionImage: {
    width: 48
  },
  customOption: {
    // width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    // paddingLeft: 15,
    // paddingRight: 15,
    // marginTop: 5,
    // marginBottom: 5,
    '&:hover': {
      backgroundColor: colors.grey[200],
      cursor: 'pointer'
    }
  },
  optionText: {
    fontSize: 16,
    marginLeft: 10,
    lineHeight: 1.2
  },
  logoVisible: {
    width: 64
  },
  logoWrapper: {
    border: `3px dashed ${colors.grey[500]}`
  }
}));

function EditEventModal({ handleDialog, event, open }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  //   useEffect(() => {
  //     console.log(event);
  //   }, [event]);

  const [loading, setLoading] = useState(false);
  const [eventCode, setEventCode] = useState(event.event_code);
  const [description, setEventDescription] = useState(event.description);
  const [date, setEventDate] = useState(event.date);
  const [time, setEventTime] = useState(event.time);
  const [logoUrl, setLogoUrl] = useState({
    value: event.logoUrl,
    label: event.logoUrl
  }); // Formatted for react-select component
  const [logoData, setLogoData] = useState([]);

  const handleChange = (event, type) => {
    let value = event.target.value;
    if (type === 'event-code') setEventCode(value);
    if (type === 'event-description') setEventDescription(value);
    if (type === 'event-date') setEventDate(value);
    if (type === 'event-time') setEventTime(value);
  };

  const handleSubmit = event => {
    event.preventDefault();
    updateEvent();
  };

  // Parsing team's logo data from
  // csv string
  useEffect(() => {
    csvtojson()
      .fromString(logourldata)
      .then(data => {
        let formatted = data.map(team => {
          team.value = team.team_thumb_img;
          team.label = team.team_name_full;
          return team;
        });
        setLogoData(formatted);
      });
  }, []);

  const updateEvent = async () => {
    setLoading(true);
    const endpoint = `${API_BASE_URL}/event/edit`;
    try {
      let response = await axios.post(endpoint, {
        eventId: event.id,
        description,
        date,
        time,
        logoUrl: logoUrl?.value || ''
      });

      if (response.status === 200) {
        await dispatch(eventActions.loadAllEvents());
        toast.success('Event updated successfully');
        handleDialog('close');
      }
    } catch (error) {
      toast.error('Error while updating event, please try again');
    }
    setLoading(false);
  };

  return (
    <Dialog
      open={open}
      disableEscapeKeyDown={false}
      disableBackdropClick={false}
      className={classes.root}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle disableTypography>
        <Typography variant="h4" color="textPrimary">
          Edit Event
        </Typography>
      </DialogTitle>
      <Divider />

      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Box mb={2} p={2} className={classes.logoWrapper}>
            <Box textAlign="center" p={2}>
              {!logoUrl?.value ? (
                <DateRangeOutlined
                  style={{ color: colors.grey[400], fontSize: 48 }}
                />
              ) : (
                <img className={classes.logoVisible} src={logoUrl.value} />
              )}
            </Box>
            <InputLabel className={classes.logoSelectLabel}>
              Event Logo
            </InputLabel>
            <CreatableSelect
              closeMenuOnSelect={true}
              value={logoUrl}
              isClearable={true}
              name="event-logo"
              placeholder="Select or Insert Logo"
              menuPortalTarget={document.body}
              menuPosition={'fixed'}
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              options={logoData}
              formatCreateLabel={inputValue => (
                <Box
                  onClick={() =>
                    setLogoUrl({ value: inputValue, label: inputValue })
                  }
                >
                  <span style={{ fontWeight: 'bold' }}>Click to select</span>{' '}
                  <span>"{inputValue}"</span>
                </Box>
              )}
              components={{
                Option: option => (
                  <Box
                    pl={2}
                    pr={2}
                    mt={1}
                    mb={1}
                    className={classes.customOption}
                    onClick={() =>
                      option.setValue({
                        value: option.value,
                        label: option.value
                      })
                    }
                  >
                    <img
                      className={classes.optionImage}
                      src={option.value}
                      alt=""
                    />
                    <Typography
                      className={classes.optionText}
                      variant="subtitle1"
                      color="textSecondary"
                    >
                      {option.label}
                    </Typography>
                  </Box>
                )
              }}
              onChange={option => {
                option &&
                  setLogoUrl({
                    value: option.value,
                    label: option.value
                  });
                !option && setLogoUrl(null);
              }}
            />
          </Box>

          <Box mb={2}>
            <FormControl variant="outlined" size="small" disabled fullWidth>
              <InputLabel className={classes.inputLabel} htmlFor="event-code">
                Event code
              </InputLabel>
              <OutlinedInput
                id="event-code"
                value={eventCode}
                onChange={event => handleChange(event, 'event-code')}
                type="text"
              />
            </FormControl>
          </Box>
          <Box mb={2}>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel
                htmlFor="event-description"
                className={classes.inputLabel}
              >
                Event description
              </InputLabel>
              <OutlinedInput
                id="event-description"
                value={description}
                onChange={event => handleChange(event, 'event-description')}
                type="text"
              />
            </FormControl>
          </Box>
          <Box mb={2}>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel htmlFor="event-date" className={classes.inputLabel}>
                Event Date
              </InputLabel>
              <OutlinedInput
                id="event-date"
                value={date}
                onChange={event => handleChange(event, 'event-date')}
                type="date"
              />
            </FormControl>
          </Box>

          <Box mb={2}>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel htmlFor="event-time" className={classes.inputLabel}>
                Event Time
              </InputLabel>
              <OutlinedInput
                id="event-time"
                value={time}
                onChange={event => handleChange(event, 'event-time')}
                type="time"
              />
            </FormControl>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button color="primary" onClick={() => handleDialog('close')}>
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Save
            {loading && (
              <CircularProgress
                size={12}
                color="primary"
                style={{
                  marginLeft: 5
                }}
              />
            )}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
export default EditEventModal;
