import React, { useEffect, useState } from 'react';

import { Card } from '@material-ui/core';
import { Title } from '@material-ui/icons';
import Logo from '../components/Logo';
import './NoInventoryErrorCard.scss';
import { logo, email, phoneNumber, teamNameShort } from './data/environment';
import { CoyotesLogo } from '../components/CoyotesLogo';

export const NoInventoryErrorCard = () => {
  return (
    <Card className="main-card">
      <div className="logo-wrapper">
        <img
          alt="Logo"
          src={logo}
          style={{
            width: 100
          }}
        />
      </div>
      <div className="body-text">
        <h3>Howdy!</h3>
        <p>
          Credit redemptions through the portal are temporarily closed. Existing
          reservations will be available in your team account shortly. Please
          get in touch with your {teamNameShort} Account Representative to
          redeem your FSA credits.
        </p>
        <p>
          We will notify you when the portal opens again for redemption. See you
          then!
        </p>
        <p>
          Phone: <a href={`tel: ${phoneNumber}`}>{phoneNumber}</a>
        </p>
        <p>
          Email: <a href={`mailto:${email}`}>{email}</a>
        </p>
      </div>
    </Card>
  );
};
