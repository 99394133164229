import React from 'react';
import { Box, makeStyles, Button, darken } from '@material-ui/core';
import { ACCOUNT_MANAGER_LINK } from 'src/views/data/environment';
import tmIcon from '../images/tm-icon.png';
import clsx from 'clsx';

export default function AccountManagerLink({ label }) {
  const classes = useStyles();

  return (
    <Button
      disableElevation
      className={clsx(classes.button, classes.tmButton)}
      size="large"
      variant="contained"
      color="primary"
      fullWidth
      onClick={() => window.open(ACCOUNT_MANAGER_LINK, '_blank')}
    >
      <img src={tmIcon} alt="" className={classes.tmIcon} />
      {label}
    </Button>
  );
}

const useStyles = makeStyles(theme => ({
  button: {
    // width: 200,
    fontSize: 16,
    borderRadius: 50,
    fontFamily: 'Open Sans',
    position: 'relative',

    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  },
  tmButton: {
    backgroundColor: '#026cdf',

    '&:hover': {
      backgroundColor: darken('#026cdf', 0.2)
    }
  },
  tmIcon: {
    width: 10,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: 20
  }
}));
