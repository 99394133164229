import React from 'react';
import {
  Paper,
  Box,
  Typography,
  makeStyles,
  Avatar,
  colors
} from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';
import { teamName, phoneNumber } from 'src/views/data/environment';

export default function WarningContent() {
  const styles = useStyles();
  return (
    <Paper variant="outlined">
      <Box p={2} className={styles.warningWrapper}>
        <Box>
          <Avatar className={styles.avatar} variant="rounded">
            <ErrorOutline className={styles.warningIcon} />
          </Avatar>
        </Box>
        <Box>
          <Box>
            <Typography variant="h5" className={styles.title}>
              Time To Reload Your Account Credit
            </Typography>
          </Box>
        </Box>

        <Box className={styles.bottomRow} mt={1}>
          <Typography variant="subtitle1" className={styles.subtitle}>
            Your {teamName} account credit balance has been spent. Please
            contact sales at{' '}
            <a className="modal-phone" href="tel:${phoneNumber}">
              {phoneNumber}
            </a>{' '}
            to re-load your account credit.
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
}

const useStyles = makeStyles(theme => ({
  warningWrapper: {
    display: 'grid',
    gridTemplateColumns: '55px 1fr',
    gridTemplateRows: 'auto auto',
    alignItems: 'center'
  },
  bottomRow: {
    gridColumn: '1 / span 2'
  },
  avatar: {
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.divider}`
  },
  warningIcon: {
    color: theme.palette.warning.main
  },
  title: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 18
  },
  subtitle: {
    fontFamily: 'Poppins',
    fontSize: 14,
    color: colors.grey[600]
  }
}));
