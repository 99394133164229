import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from '../../../components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import '../../css/admin.scss';
import DashboardLayout from '../../../layouts/DashboardLayout';
import * as customerActions from '../../../state/customer/customerAction';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const TransactionsListView = () => {
  const customerTransactions = useSelector(state =>
    state.customers.customerTransactions
      ? state.customers.customerTransactions
      : []
  );

  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(customerActions.loadCustomerTransactions());
  }, []);

  // useEffect(() => {
  //   if (customerTransactions) {
  //     console.log(customerTransactions);
  //   }
  // }, [customerTransactions]);

  customerTransactions.sort(function(a, b) {
    var keyA = new Date(a.dateCreated),
      keyB = new Date(b.dateCreated);
    if (keyA > keyB) return -1;
    if (keyA < keyB) return 1;
    return 0;
  });

  return (
    <DashboardLayout>
      <Page className={classes.root} title="Transactions">
        <Container maxWidth={false}>
          <Toolbar customerTransactions={customerTransactions} />
          <Box mt={3}>
            <Results customerTransactions={customerTransactions} />
          </Box>
        </Container>
      </Page>
    </DashboardLayout>
  );
};

export default TransactionsListView;
