import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from '../../../components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import * as customerActions from '../../../state/customer/customerAction';
import DashboardLayout from '../../../layouts/DashboardLayout';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CustomerListView = () => {
  const customers = useSelector(state =>
    state.customers.customers ? state.customers.customers : []
  );

  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(customerActions.loadAllCustomer());
  }, []);

  return (
    <DashboardLayout>
      <Page className={classes.root} title="Customers">
        <Container maxWidth={false}>
          <Toolbar customers={customers} />
          <Box mt={3}>
            <Results customers={customers} />
          </Box>
        </Container>
      </Page>
    </DashboardLayout>
  );
};

export default CustomerListView;
