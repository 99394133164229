import React from 'react';

import {
  Box,
  Typography,
  Paper,
  Drawer,
  makeStyles,
  colors
} from '@material-ui/core';
import { HighlightOff } from '@material-ui/icons';
import MenuItems from './MenuItems';

export default function Sidebar({ open, handleSidebar, accountId }) {
  const styles = useStyles();
  return (
    <Drawer
      PaperProps={{
        className: styles.sidebarPaper
      }}
      BackdropProps={{
        className: styles.backdrop
      }}
      anchor="right"
      {...{ open }}
      onClose={handleSidebar(false)}
    >
      <Box className={styles.sidebarContainer}>
        <HighlightOff
          className={styles.closeIcon}
          onClick={handleSidebar(false)}
        />
        <Box pt={8} pb={8} pl={1} pr={1} className={styles.itemWrapper}>
          <MenuItems {...{ handleSidebar, accountId }} />
        </Box>
      </Box>
    </Drawer>
  );
}

const useStyles = makeStyles(theme => ({
  backdrop: {
    backgroundColor: `rgba(0,0,0,0.2)`
  },
  sidebarPaper: {
    // backgroundColor: colors.grey[100],
    borderLeft: `1px solid rgba(0,0,0,0.3)`,
    boxShadow: '0 8px 16px rgb(0 0 0 / 10%)'
  },
  sidebarContainer: {
    width: 250,
    position: 'relative',
    display: 'flex',
    alignItems: 'center'
  },
  closeIcon: {
    position: 'absolute',
    top: 15,
    right: 15,
    fontSize: 28,
    color: colors.grey[600],
    cursor: 'pointer'
  },
  itemWrapper: {
    height: 'fit-content',
    width: '100%'
  }
}));
