import React from 'react';
import {
  Box,
  makeStyles,
  useTheme,
  useMediaQuery,
  colors
} from '@material-ui/core';
import { HighlightOffRounded } from '@material-ui/icons';

export default function TopPanel({ handleConfirmDrawer }) {
  const styles = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box className={styles.topPanel}>
      {sm && (
        <span
          className={styles.mobileTopBar}
          onClick={() => handleConfirmDrawer(false)}
        />
      )}
      <HighlightOffRounded
        className={styles.closeIcon}
        onClick={() => handleConfirmDrawer(false)}
      />
    </Box>
  );
}

const useStyles = makeStyles(theme => ({
  topPanel: {
    backgroundColor: 'white',
    width: '100%',
    height: 40,
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 15
  },
  closeIcon: {
    position: 'absolute',
    top: 10,
    right: 10,
    color: colors.grey['600'],
    transition: 'all 0.2s ease',
    cursor: 'pointer',
    fontSize: 32,

    '&:hover': {
      color: colors.grey[500]
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: 28
    }
  },
  mobileTopBar: {
    position: 'absolute',
    top: 8,
    left: '50%',
    transform: 'translateX(-50%)',
    width: 80,
    height: 8,
    display: 'block',
    backgroundColor: colors.grey['200'],
    borderRadius: 8,
    transition: 'all 0.2s ease',

    '&:active': {
      border: `1px solid ${colors.grey['400']}`
    }
  }
}));
