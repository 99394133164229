import * as types from './actionTypes';
import { authApi } from '../../api/authApi';

import {
  beginApiCall,
  apiCallError,
  endApiCall
} from '../apiStatus/apiStatusActions';
import { apiReponseError } from '../../api/config/apiRequestError';

export const loginWithJWT = user => {
  return dispatch => {
    dispatch(beginApiCall());
    return authApi
      .authenticateUser(user)
      .then(response => {
        if (response.data) {
          return response.data;
        }
      })
      .catch(error => {
        dispatch(apiCallError());
        apiReponseError(error);
      })
      .finally(() => {
        dispatch(endApiCall());
      });
  };
};
