import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../css/product.scss';
import {
  siteTitle,
  banner,
  THEME,
  IMAGE_BASE_URL,
  API_BASE_URL,
  logo,
  teamNameShort
} from 'src/views/data/environment';
import Loader from 'src/components/Loader';
import swal from 'sweetalert2';
import { useHistory, useLocation } from 'react-router-dom';
import * as eventActions from 'src/state/event/eventAction';
import * as customerActions from 'src/state/customer/customerAction';
import Viewer from 'react-viewer';
import { ToastContainer } from 'react-toastify';
import { Button } from '@material-ui/core';
import { NavBar } from 'src/components/NavBar';
import { Box, useTheme, Container, Grid } from '@material-ui/core';
import TransactionsPanel from './TransactionsPanel';
import NoTransaction from './NoTransaction';
import BottomPanel from './BottomPanel';

// const dummyData = [
//   {
//     numSeats: 2,
//     seatBlock: [{ section_name: 508 }],
//     dateCreated: '9/5/2021',
//     reserveOrderNumber: '45654645',
//     purchaseOrderNumber: '654654',
//     totalCost: 586,
//     eventCode: 'ESR0102M'
//   },

//   {
//     numSeats: 4,
//     seatBlock: [{ section_name: 604 }],
//     dateCreated: '9/5/2021',
//     reserveOrderNumber: '45654645',
//     purchaseOrderNumber: 'NONE',
//     totalCost: 1290,
//     eventCode: 'ESR0102M'
//   },

//   {
//     numSeats: 4,
//     seatBlock: [{ section_name: 604 }],
//     dateCreated: '9/5/2021',
//     reserveOrderNumber: '45654645',
//     purchaseOrderNumber: '64654',
//     totalCost: 30286,
//     eventCode: 'ESR0102M'
//   },

//   {
//     numSeats: 4,
//     seatBlock: [{ section_name: 604 }],
//     dateCreated: '9/5/2021',
//     reserveOrderNumber: '45654645',
//     purchaseOrderNumber: 'NONE',
//     totalCost: 1937,
//     eventCode: 'ESR0102M'
//   }
// ];

const Summary = () => {
  const theme = useTheme();
  const events = useSelector(state =>
    state.events.events ? state.events.events : []
  );

  React.useEffect(() => {}, [events]);

  const setBlocks = useSelector(state =>
    state.events.seatBlocks ? state.events.seatBlocks : []
  );

  const customerCredit = useSelector(state =>
    state.customers.customerCredits ? state.customers.customerCredits : []
  );

  const customerTransactionHistory = useSelector(state =>
    state?.customers?.customerHistory ? state.customers.customerHistory : null
  );

  const customerTransactions =
    customerTransactionHistory?.transactions?.transactions || [];

  // const [customerTransactions] = useState(dummyData);

  const dispatch = useDispatch();

  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [accountId, setAccountId] = useState('');
  const [isSelectionSubmitted, setIsSelectionSubmitted] = useState(false);

  const selectionSubmit = submit => setIsSelectionSubmitted(submit);

  useEffect(() => {
    if (location.state && location.state['accountId']) {
      const accountId = location.state['accountId'];
      if (accountId) {
        dispatch(eventActions.loadAllFutureEvents());
        dispatch(customerActions.loadCustomerCredit(accountId));
        // dispatch(customerActions.loadCustomerTransactions(accountId));
        dispatch(customerActions.loadCustomerHistory(accountId));
        setAccountId(accountId);
      }
      window.mixpanel.track('Transaction History', {});
    } else {
      history.replace('/');
    }
  }, []);

  return (
    <div className={THEME}>
      {loading && <Loader />}
      <ToastContainer />
      <NavBar accountId={accountId} />
      <Box
        className="banner"
        style={{ backgroundColor: theme.palette.primary.main }}
      >
        <h1 className="banner-heading">CREDIT HISTORY</h1>
        <p className="banner-para">View past transactions</p>
      </Box>

      <Container maxWidth={false}>
        <Box mt={4}>
          <Grid container justify="center" spacing={2}>
            <Grid item xs={12} lg={10} xl={8}>
              {customerTransactionHistory &&
                customerTransactions.length > 0 && (
                  <TransactionsPanel
                    {...{ customerTransactions, customerTransactionHistory }}
                  />
                )}
            </Grid>

            <Grid item xs={12} lg={10} xl={8}>
              {customerTransactions.length == 0 && (
                <NoTransaction
                  {...{ customerTransactions, customerTransactionHistory }}
                />
              )}
            </Grid>
          </Grid>
        </Box>
      </Container>

      {isSelectionSubmitted && <BottomPanel {...{ selectionSubmit }} />}
      <div className="space-bottom"></div>
    </div>
  );
};

export default Summary;
