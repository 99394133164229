import React from 'react';
import {
  Box,
  Typography,
  makeStyles,
  Container,
  Grid,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import {
  BANNER_OVERLAY,
  SUBDOMAIN,
  WHITE_LABEL_TEAM_ID
} from '../views/data/environment';

export default function Banner({ background }) {
  const classes = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Box
      className={classes.banner}
      style={{
        backgroundImage: `url(${background})`
      }}
    >
      <Container className={classes.container}></Container>
    </Box>
  );
}

const useStyles = makeStyles(theme => ({
  banner: {
    height: '60vh',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundBlendMode: 'overlay',
    backgroundColor: `rgba(0,0,0,0)`,
    position: 'relative',

    [theme.breakpoints.down('md')]: {
      height: 450
    },

    [theme.breakpoints.down('xs')]: {
      height: 250
    }
  },
  container: {
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  }
}));
