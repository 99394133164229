import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import bluebadge from 'src/images/blue-badge.png';
import coyotesMap from 'src/images/coyotes-map.png';
import 'src/views/css/product.scss';
import {
  siteTitle,
  banner,
  THEME,
  IMAGE_BASE_URL,
  API_BASE_URL,
  logo,
  development,
  WHITE_LABEL_TEAM_ID,
  seatMap,
  teamNameShort,
  phoneNumber,
  teamName
} from 'src/views/data/environment';
import Loader from 'src/components/Loader';
import swal from 'sweetalert2';
import { useHistory, useLocation } from 'react-router-dom';
import * as eventActions from 'src/state/event/eventAction';
import * as customerActions from 'src/state/customer/customerAction';
import Viewer from 'react-viewer';
import { ToastContainer } from 'react-toastify';
import { NoInventoryErrorCard } from 'src/views/NoInventoryErrorCard';
import { Button } from '@material-ui/core';
import moment from 'moment';
import tConv24 from 'src/utils/utils';
import { NavBar } from 'src/components/NavBar';
import Axios from 'axios';
import { Bell as BellIcon } from 'react-feather';
import { Select, MenuItem } from '@material-ui/core';
import { useTheme } from '@material-ui/core';
import { Box, Grid, Container } from '@material-ui/core';
import GameSelector from './GameSelector';
import SectionSelector from './SectionSelector';
import SeatsPanel from './SeatsPanel';
import SeatCountSelector from './SeatCountSelector';
import BottomPanel from './BottomPanel';
import { makeStyles, colors } from '@material-ui/core';
import * as apiStatusActions from 'src/state/apiStatus/apiStatusActions';
import FloatingButton from './FloatingButton';

const Products = () => {
  const styles = useStyles();
  const theme = useTheme();
  const events = useSelector(state =>
    state.events.events ? state.events.events : []
  );

  const seatBlocks = useSelector(state =>
    state.events.seatBlocks ? state.events.seatBlocks : []
  );

  const apiRequestInProgress = useSelector(state => {
    return state.apiRequestInProgress;
  });

  const customerCredit = useSelector(state =>
    state.customers.customerCredits ? state.customers.customerCredits : {}
  );

  const dispatch = useDispatch();

  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [isEnabled, setIsEnabled] = useState(true);
  const [showMapImage, setShowMapImage] = useState(false);
  const [isSelectionSubmitted, setIsSelectionSubmitted] = useState(false);

  const [activeEventIndex, setActiveEventIndex] = useState(0);
  const [activeSeatCountIndex, setActiveSeatCountIndex] = useState(1);
  // Events in season

  const [creditBalance, setCreditBalance] = useState(0);
  const [accountId, setAccountId] = useState('');
  const [eventCodeDetail, setEventCodeDetail] = useState('');
  const [sections, setSections] = useState([]);
  const [selectedSection, setSelectedSection] = useState('all');
  const [seatState, setSeatState] = useState([]);

  const [eventsLoading, setEventsLoading] = useState(false);

  // useEffect(() => {
  //   apiRequestInProgress && setLoading(true);
  //   !apiRequestInProgress && setLoading(false);
  // }, [apiRequestInProgress]);

  const seatCountList = [
    { name: 'item1' },
    { name: 'item2' },
    { name: 'item3' },
    { name: 'item4' },
    { name: 'item5' },
    { name: 'item6' },
    { name: 'item7' },
    { name: 'item8' }
  ];

  const openMapImage = () => setShowMapImage(true);
  const selectionSubmit = submit => setIsSelectionSubmitted(submit);

  useEffect(() => {
    // Getting unique section names
    let sections = [];
    seatBlocks.map(seat => {
      let section_name = seat.section_name;
      if (!sections.includes(section_name)) sections.push(section_name);
    });

    // Sorting the sections array in numerical order
    sections.sort((a, b) => Number(a) - Number(b));

    // Sorting the seatBlocks by section number
    seatBlocks.sort((a, b) => Number(a.section_name) - Number(b.section_name));

    // Updating the states
    setSections(sections);
    setSeatState(seatBlocks);
  }, [seatBlocks]);

  useEffect(() => {
    if (selectedSection === 'all') {
      setSeatState(seatBlocks);
    } else {
      let sortedSeats = seatBlocks.sort();
    }
  }, [selectedSection]);

  const handleSortSeat = event => {
    let section = event.target.value;
    setSelectedSection(section);

    let sortedSeats = seatBlocks.filter(seat => seat.section_name === section);
    setSeatState(sortedSeats);
  };

  const handleSubscribeClick = async () => {
    window.mixpanel.track('Get notified clicked', {});
    const { value: phoneNumber } = await swal.fire({
      title: 'Get notified when seats are available for this matchup',
      input: 'number',
      inputLabel: 'Enter your phone number',
      inputValue: '',
      showCancelButton: false,
      customClass: {
        confirmButton: 'subscribe-confirm-btn',
        input: 'subs-phone-input'
      },
      confirmButtonText: 'Subscribe',
      inputValidator: value => {
        if (!value) {
          return 'Enter a valid phone number';
        }
      }
    });

    if (phoneNumber) {
      try {
        let endpoint = `${API_BASE_URL}/event/set_reminder`;
        const seatCount = activeSeatCountIndex + 1;
        const eventCode = events[activeEventIndex].event_code;
        const teamId = WHITE_LABEL_TEAM_ID;

        let response = await Axios.post(endpoint, {
          phoneNumber,
          eventCode,
          seatCount,
          teamId
        });

        if (response.status === 200) {
          swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Well notify you when seats become available!'
          });
          window.mixpanel.track('Subscribed to reminders', {});
        }
      } catch (error) {
        swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Something went wrong, please try again'
        });
      }

      // console.log(phoneNumber);
      // console.log(seatCount);
      // console.log(eventCode);
      // console.log(teamId);
    }
  };

  // Refactored to v2
  const handleEventClick = async (eventCode, index) => {
    setEventsLoading(true);
    setEventCodeDetail(eventCode);
    setActiveEventIndex(index);
    const eventTicketData = {
      eventCode: eventCode,
      numSeats: activeSeatCountIndex + 1,
      accountId: accountId
    };
    await dispatch(eventActions.loadSections(eventTicketData));
    window.mixpanel.track('Selected a game', {
      eventCode: eventCode
    });
    setEventsLoading(false);
  };

  const handleSeatCountClick = index => {
    setActiveSeatCountIndex(index);
    setSelectedSection('all');
    const eventTicketData = {
      eventCode: eventCodeDetail,
      numSeats: index + 1,
      accountId: accountId
    };
    dispatch(eventActions.loadSections(eventTicketData));
    dispatch(customerActions.loadCustomerCredit(accountId));
    window.mixpanel.track('Seat count click', {
      seatCount: eventTicketData.numSeats
    });
  };

  const handleLocationClick = (
    pkId,
    sectionName,
    price,
    rowNum,
    ticketTypeCode
  ) => {
    let availableCredit = parseFloat(
      customerCredit.creditAvailable['$numberDecimal']
    );

    // multiply price by number of seats
    if (availableCredit < price * (activeSeatCountIndex + 1)) {
      swal.fire({
        icon: 'warning',
        title: 'Time To Reload Your Account Credit',
        html: `<p class="modal-text" >Your ${teamName} account credit balance has been spent. Please contact sales at 
        <a class="modal-phone" href="tel:${phoneNumber}">${phoneNumber}</a> to re-load your account credit. </p>`,
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: 'DISMISS',
        allowOutsideClick: false,
        allowEscapeKey: false,
        showCloseButton: true
      });
      return;
    }

    window.mixpanel.track('Seen confirm prompt', {});
    const date = moment(events[activeEventIndex]['date']).format(
      'dddd MMMM DD, YYYY'
    );
    const time = tConv24(events[activeEventIndex]['time']);
    swal
      .fire({
        title: '<strong>Confirm Date and Seats</strong>',
        icon: 'info',
        html:
          `
          <span>Date: <b>${date} ${time}</b></span></br></br>
          <b>${activeSeatCountIndex + 1} ${
            activeSeatCountIndex == 0 ? 'Seat' : 'Seats'
          } </b>in <b> Section ${sectionName}, Row ${rowNum} </b></br>` +
          `Total Cost <b>$${((activeSeatCountIndex + 1) * price).toFixed(
            2
          )}</b></br>` +
          `Credit balance after purchase: <b>$${parseFloat(
            customerCredit.creditAvailable['$numberDecimal'] -
              (activeSeatCountIndex + 1) * price
          ).toFixed(2)}</b></br>`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Confirm',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        cancelButtonText: 'Cancel',
        cancelButtonAriaLabel: 'Thumbs down',
        allowOutsideClick: false
      })
      .then(async result => {
        if (result.isConfirmed) {
          setLoading(true);
          const confirmSeatData = {
            PK_ID: pkId,
            numSeats: activeSeatCountIndex + 1,
            eventCode: eventCodeDetail,
            sectionName: sectionName,
            accountId: accountId,
            rowNum: rowNum,
            ticketTypeCode: ticketTypeCode
          };
          const ticketBook = await eventActions.confirmTicket(confirmSeatData);
          setLoading(false);
          if (ticketBook) {
            window.mixpanel.track('Purchased seats', {
              totalCost: `${((activeSeatCountIndex + 1) * price).toFixed(2)}`
            });
            swal
              .fire({
                title: '<strong>Success!</strong>',
                icon: 'success',
                html: `Your order for <b>${activeSeatCountIndex + 1} ${
                  activeSeatCountIndex == 0 ? 'Seat' : 'Seats'
                }</b> in <b>Section ${
                  ticketBook.seatBlock[0].section_name
                }</b> has been placed. A receipt has been sent to your email. Your tickets will be available in your Team Account shortly.`,
                confirmButtonText: 'Done',
                allowOutsideClick: false,
                showCancelButton: true,
                cancelButtonText: 'Choose more events'
              })
              .then(res => {
                dispatch(customerActions.loadCustomerCredit(accountId));
                // update inventory
                const eventTicketData = {
                  eventCode: eventCodeDetail,
                  numSeats: activeSeatCountIndex + 1,
                  accountId: accountId
                };
                // refactored to v2
                dispatch(eventActions.loadSections(eventTicketData));
                if (res.dismiss == 'cancel') {
                } else {
                  history.push('/thankyou');
                }
              });
          }
        }
      });
  };

  const handleRegularPurchase = async seat => {
    setLoading(true);
    const confirmSeatData = {
      PK_ID: seat.PK_ID,
      numSeats: activeSeatCountIndex + 1,
      eventCode: eventCodeDetail,
      sectionName: seat.section_name,
      accountId: accountId,
      rowNum: seat.row_num,
      ticketTypeCode: seat.ticket_type_code
    };
    const ticketBook = await eventActions.confirmTicket(confirmSeatData);
    setLoading(false);

    if (ticketBook) {
      window.mixpanel.track('Purchased seats', {
        totalCost: `${((activeSeatCountIndex + 1) * seat.price).toFixed(2)}`
      });
      swal
        .fire({
          title: '<strong>Success!</strong>',
          icon: 'success',
          html: `Your order for <b>${activeSeatCountIndex + 1} ${
            activeSeatCountIndex == 0 ? 'Seat' : 'Seats'
          }</b> in <b>Section ${
            ticketBook.seatBlock[0].section_name
          }</b> has been placed. A receipt has been sent to your email. Your tickets will be available in your Team Account shortly.`,
          confirmButtonText: 'Done',
          allowOutsideClick: false,
          showCancelButton: true,
          cancelButtonText: 'Choose more events'
        })
        .then(res => {
          dispatch(customerActions.loadCustomerCredit(accountId));
          // update inventory
          const eventTicketData = {
            eventCode: eventCodeDetail,
            numSeats: activeSeatCountIndex + 1,
            accountId: accountId
          };
          // refactored to v2
          dispatch(eventActions.loadSections(eventTicketData));
          if (res.dismiss == 'cancel') {
          } else {
            history.push('/thankyou');
          }
        });
    }
  };

  useEffect(() => {
    if (location.state && location.state['accountId']) {
      const accountId = location.state['accountId'];
      if (accountId) {
        dispatch(eventActions.loadAllFutureEvents());
        setAccountId(accountId);
        dispatch(customerActions.loadCustomerCredit(accountId));
      }
      Axios.post(`${API_BASE_URL}/configuration/enabled`).then(res => {
        setIsEnabled(res.data.isPortalOpen);

        if (development) setIsEnabled(true);
      });
    } else {
      history.replace('/');
    }
  }, [location]);

  useEffect(() => {
    if (customerCredit.length > 0) {
      window.mixpanel.identify(`${accountId}`);
      window.mixpanel.people.set({
        $email: customerCredit[0]['emailAddress'],
        credits: customerCredit[0].creditAvailable['$numberDecimal']
      });
      window.mixpanel.track('Viewing Inventory', {});
      window.Intercom('boot', {
        user_id: accountId
      });
    }
  }, [customerCredit]);

  useEffect(() => {
    if (events.length < 1) return;
    //refactored to v2
    setEventCodeDetail(events[activeEventIndex].event_code);
    const eventTicketData = {
      eventCode: events[activeEventIndex].event_code,
      numSeats: activeSeatCountIndex + 1,
      accountId: accountId
    };
    setLoading(true);
    // refactored to v2
    dispatch(eventActions.loadSections(eventTicketData));
  }, [events]);

  useEffect(() => {
    setLoading(false);
  }, [events]);

  const selected = 'item1';

  const getSeatNumString = (availableSeat, numSeats) => {
    let availableSeat2 = availableSeat.replaceAll(' ', '');
    let explode = availableSeat2.split('-');
    let ret = 'Seats: ';
    numSeats = activeSeatCountIndex + 1;
    for (let i = 0; i < numSeats; i++) {
      if (i == numSeats - 1) ret = ret + (parseInt(explode[3]) + i);
      else ret = ret + (parseInt(explode[3]) + i) + ', ';
    }
    return ret;
  };

  return (
    <div className={styles.page}>
      {loading && <Loader />}
      <Viewer
        visible={showMapImage}
        onClose={() => {
          setShowMapImage(false);
        }}
        images={[{ src: seatMap, alt: '' }]}
      />
      <ToastContainer />
      <NavBar accountId={accountId} />
      <div
        className="banner"
        style={{ backgroundColor: theme.palette.primary.main }}
      >
        <h1 className="banner-heading">{banner.choose}</h1>
        <p className="banner-para">{banner.desc}</p>
      </div>

      <Container maxWidth={false}>
        <Box mt={4}>
          {isEnabled && events.length > 0 && (
            <>
              <Box>
                <Grid container justify="center" spacing={2}>
                  <Grid item xs={12} lg={10} xl={8}>
                    <GameSelector
                      {...{
                        events,
                        handleEventClick,
                        activeEventIndex,
                        eventCodeDetail,
                        apiRequestInProgress,
                        setActiveEventIndex,
                        setEventCodeDetail,
                        eventsLoading
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} lg={10} xl={8}>
                    <Grid container justify="center" spacing={2}>
                      <Grid item xs={12} md={6}>
                        <SeatCountSelector
                          {...{
                            handleSeatCountClick,
                            activeSeatCountIndex,
                            openMapImage
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <SectionSelector
                          {...{ selectedSection, handleSortSeat, sections }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} lg={10} xl={8}>
                    <SeatsPanel
                      {...{
                        seatState,
                        handleLocationClick,
                        getSeatNumString,
                        activeSeatCountIndex,
                        selectedSection,
                        isEnabled,
                        events,
                        apiRequestInProgress,
                        seatBlocks,
                        handleSubscribeClick,
                        customerCredit,
                        activeEventIndex,
                        handleRegularPurchase
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
        </Box>
      </Container>
      <FloatingButton {...{ openMapImage }} />
      <BottomPanel
        {...{
          customerCredit,
          selectionSubmit,
          isSelectionSubmitted,
          apiRequestInProgress
        }}
      />

      <div className="main-cont">
        {isEnabled == false && <NoInventoryErrorCard />}
      </div>
      <div className="whole-footer">
        <div className="footer">
          <div className="badge-cont">
            <img src={bluebadge} className="footer-badge" alt="logooovhvkj1" />
            <p className="foot-texts">Built for fans</p>
          </div>
          <div className="links-cont">
            <b>
              <p className="foot-texts">Privacy Policy</p>
            </b>
            <b>
              <p className="foot-texts">About us</p>
            </b>
            <b>
              <p className="foot-texts">Terms of services</p>
            </b>

            <b>
              <p className="foot-texts">Press</p>
            </b>
          </div>
        </div>
        <div className="copyright-cont">
          <p className="bottom-texts">Copyright 2020 Season Share Inc.</p>
        </div>
      </div>
      <div className="space-bottom"></div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  page: {
    // backgroundColor: colors.grey[100]
  }
}));

export default Products;
