import React, { useState } from 'react';
import {
  Box,
  Typography,
  makeStyles,
  Container,
  Grid,
  useMediaQuery,
  useTheme,
  colors,
  Button,
  lighten,
  darken,
  Backdrop,
  CircularProgress,
  Paper
} from '@material-ui/core';
import {
  BANNER_OVERLAY,
  SUBDOMAIN,
  WHITE_LABEL_TEAM_ID
} from '../views/data/environment';
import {
  ListAltOutlined,
  ArrowRightAltOutlined,
  NoEncryption
} from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

export default function Banner({
  background,
  title,
  subtitle,
  isPortalOpen,
  teamAccountUrl
}) {
  const classes = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const history = useHistory();
  const [backdropOpen, setBackdropOpen] = useState(false);

  React.useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    const login = params.get('login');
    if (login) {
      setBackdropOpen(true);
      // auto login
      setTimeout(() => {
        try {
          document.getElementById('get-started-button').click();
        } catch (error) {
          console.log('Button doesnt exist');
        }
        setBackdropOpen(false);
      }, 1000);
    }
  }, []);

  const linkAccount = () => {
    let replaced = teamAccountUrl.replace(
      'REDIRECT_URL',
      `https://${SUBDOMAIN}.seasonshare.com/tmcallback/linkAccount`
    ); // redirect back to where we came from, handle in params
    let finalUrl = replaced.replace('STATE', `UPLOAD-${WHITE_LABEL_TEAM_ID}`);
    window.location.href = `${finalUrl}`;
  };

  return (
    <>
      <Backdrop className={classes.backdrop} open={backdropOpen}>
        <Paper>
          <Box p={4}>
            <Box textAlign="center">
              <CircularProgress size={32} color="primary" />
            </Box>
            <Box textAlign="center" mt={2}>
              <Typography variant="h6" className={classes.loadingText}>
                Logging you in automatically
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Backdrop>
      <Box
        className={classes.banner}
        style={{
          backgroundImage: `url(${background})`
        }}
      >
        <Container className={classes.container}>
          <Grid container justify="center">
            <Grid item xs={12}>
              <Box textAlign="center">
                <Typography variant="h1" className={classes.title}>
                  {title}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box textAlign="center" mt={2}>
                <Typography variant="h1" className={classes.subtitle}>
                  {subtitle}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12}>
              {isPortalOpen && (
                <Box
                  textAlign="center"
                  marginTop={title && subtitle ? 2 : xs ? 12 : 24}
                >
                  <Button
                    disableElevation
                    variant="contained"
                    size="large"
                    id="get-started-button"
                    color="primary"
                    className={classes.button}
                    onClick={linkAccount}
                  >
                    {/* <ListAltOutlined className={classes.buttonIcon} /> */}
                    GET STARTED
                    <ArrowRightAltOutlined className={classes.animatedIcon} />
                  </Button>
                </Box>
              )}
            </Grid>
          </Grid>
        </Container>

        {!isPortalOpen && (
          <Box className={classes.noticeWrapper} pt={1} pb={1}>
            <Typography variant="h5" className={classes.notice}>
              Credit portal is temporarily closed. You will be notified via
              email when we open back up. Thank you!
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'rgba(0,0,0,0.4)',
    backdropFilter: 'blur(4px)'
  },

  loadingText: {
    fontSize: 16,
    fontFamily: 'Raleway',
    fontWeight: 600,
    color: colors.grey[600]
  },
  banner: {
    height: '60vh',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundBlendMode: 'overlay',
    backgroundColor: `rgba(0,0,0,${BANNER_OVERLAY})`,
    position: 'relative',

    [theme.breakpoints.down('md')]: {
      height: 450
    },

    [theme.breakpoints.down('xs')]: {
      height: 250
    }
  },
  container: {
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    color: 'white',
    fontSize: 64,
    fontFamily: 'Raleway',
    fontWeight: 700,

    [theme.breakpoints.down('md')]: {
      fontSize: 48
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: 28
    }
  },
  subtitle: {
    color: 'white',
    fontSize: 42,
    fontFamily: 'Raleway',
    fontWeight: 400,
    opacity: 0.8,

    [theme.breakpoints.down('md')]: {
      fontSize: 32
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: 18
    }
  },

  noticeWrapper: {
    position: 'absolute',
    bottom: 85,
    left: '50%',
    transform: 'translateX(-50%)',
    width: '100%',
    textAlign: 'center',
    backgroundColor: 'rgba(0,0,0,0.6)',

    [theme.breakpoints.down('xs')]: {
      bottom: 15
    }
  },

  notice: {
    color: 'white',
    fontSize: 20,
    fontWeight: 600,
    fontFamily: 'Raleway',
    textAlign: 'center',
    padding: '0px 15px',

    [theme.breakpoints.down('md')]: {
      fontSize: 24
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: 14
    }
  },

  // startButtonPanel: {
  //   // backgroundColor: 'rgba(0,0,0,0.5)',
  //   // backgroundColor: 'white',
  //   padding: '40px',
  //   borderRadius: 5,

  //   [theme.breakpoints.down('xs')]: {
  //     padding: '20px'
  //   }
  // },
  button: {
    fontSize: 20,
    fontWeight: 'bold',
    fontFamily: 'Raleway',
    padding: '10px 50px',
    borderRadius: 12,
    border: '2px solid white',
    boxShadow: `rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;`,
    transition: 'all 0.2s ease',

    // '& .MuiButton-label': {
    //   display: 'flex'
    // },

    [theme.breakpoints.down('xs')]: {
      padding: '5px 25px',
      fontSize: 16
    },

    '&:hover': {
      borderRadius: 50,
      // transform: 'scale(1.02)'
      // backgroundColor: darken(theme.palette.primary.main, 0.1)
      // borderColor: darken('#FFFFFF', 0.5)
      // color: 'white'

      '& .MuiSvgIcon-root': {
        transform: 'scaleX(1)',
        width: 24,
        marginLeft: 10
      }
    }
  },

  animatedIcon: {
    transition: 'all 0.2s ease',
    transform: 'scaleX(0)',
    transformOrigin: 'left',
    width: 0,
    marginTop: -2
  }
}));
