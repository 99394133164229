import { createMuiTheme, colors } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';
import { THEME_NAME } from '../views/data/environment';

const coyotes = createMuiTheme({
  palette: {
    background: {
      dark: '#EEDEBB',
      default: colors.common.white,
      paper: colors.common.white
    },
    primary: {
      main: '#900028',
      contrastText: '#fff'
    },
    secondary: {
      main: '#B17D58',
      contrastText: '#fff'
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600]
    }
  },
  shadows,
  typography
});

const roadrunners = createMuiTheme({
  palette: {
    background: {
      dark: '#EEDEBB',
      default: colors.common.white,
      paper: colors.common.white
    },
    primary: {
      main: '#900028',
      contrastText: '#fff'
    },
    secondary: {
      main: '#B17D58',
      contrastText: '#fff'
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600]
    }
  },
  shadows,
  typography
});

const jaguars = createMuiTheme({
  palette: {
    background: {
      dark: '#EEDEBB',
      default: colors.common.white,
      paper: colors.common.white
    },
    primary: {
      main: '#006778',
      contrastText: '#fff'
    },
    secondary: {
      main: '#D7A22A',
      contrastText: '#fff'
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600]
    }
  },
  shadows,
  typography
});

const ohiostate = createMuiTheme({
  palette: {
    background: {
      dark: '#EEDEBB',
      default: colors.common.white,
      paper: colors.common.white
    },
    primary: {
      main: '#666666',
      contrastText: '#fff'
    },
    secondary: {
      main: '#bb0000',
      contrastText: '#fff'
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600]
    }
  },
  shadows,
  typography
});

const newjersey = createMuiTheme({
  palette: {
    background: {
      dark: '#EEDEBB',
      default: colors.common.white,
      paper: colors.common.white
    },
    primary: {
      main: '#C9122E',
      contrastText: '#fff'
    },
    secondary: {
      main: '#020202',
      contrastText: '#fff'
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600]
    }
  },
  shadows,
  typography
});

const rangers = createMuiTheme({
  palette: {
    background: {
      dark: '#EEDEBB',
      default: colors.common.white,
      paper: colors.common.white
    },
    primary: {
      main: '#005189',
      contrastText: '#fff'
    },
    secondary: {
      main: '#c22032',
      contrastText: '#fff'
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600]
    }
  },
  shadows,
  typography
});

const knicks = createMuiTheme({
  palette: {
    background: {
      dark: '#EEDEBB',
      default: colors.common.white,
      paper: colors.common.white
    },
    primary: {
      main: '#FF681D',
      contrastText: '#fff'
    },
    secondary: {
      main: '#003DA7',
      contrastText: '#fff'
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600]
    }
  },
  shadows,
  typography
});

const allThemes = {
  coyotes,
  roadrunners,
  jaguars,
  ohiostate,
  newjersey,
  rangers,
  knicks
};

const theme = allThemes[THEME_NAME];

export default theme;
