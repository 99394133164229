import axios from 'axios';
import {
  API_BASE_URL,
  WHITE_LABEL_TEAM_ID
} from '../../views/data/environment';

const apiClient = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    team_id: WHITE_LABEL_TEAM_ID,
    teamid: WHITE_LABEL_TEAM_ID,
  }
});

export default apiClient;
