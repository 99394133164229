import { toast } from 'react-toastify';

export const apiReponseError = error => {
  if (error.response) {
    toast.error(error.response.data.errorMessage);
  } else if (error.request) {
    toast.error('Request was not processed');
  } else {
    toast.error(error.errorMessage);
  }
};
