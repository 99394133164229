import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Container, makeStyles, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Page from '../../../components/Page';
import * as customerActions from '../../../state/customer/customerAction';
import DashboardLayout from '../../../layouts/DashboardLayout';
import axios from 'axios';
import { API_BASE_URL, WHITE_LABEL_TEAM_ID } from '../../data/environment';
import { getDefaultCompilerOptions } from 'typescript';
import CustomerBlock from './CustomerBlock';
import TransactionTable from './TransactionTable';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CustomerDetails = () => {
  const customers = useSelector(state =>
    state.customers.customers ? state.customers.customers : []
  );

  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const [details, setDetails] = useState();

  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    const account = params.get('account');

    getCustomerDetails(account);
  }, []);

  const getCustomerDetails = async account => {
    let response = await axios.get(
      `${API_BASE_URL}/transactions/customer/${account}`
    );
    setDetails(response.data.transactions);
  };

  return (
    <DashboardLayout>
      <Page className={classes.root} title="Customers">
        <Container maxWidth={false}>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={4}>
              {details && <CustomerBlock details={details} />}
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={8}>
              {details && details.transactions !== undefined && (
                <TransactionTable transactions={details.transactions} />
              )}
            </Grid>
          </Grid>
        </Container>
      </Page>
    </DashboardLayout>
  );
};

export default CustomerDetails;
