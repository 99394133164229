import React from 'react';
import {
  Box,
  Paper,
  makeStyles,
  Button,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import { MapRounded } from '@material-ui/icons';

export default function FloatingButton({ openMapImage }) {
  const styles = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Paper className={styles.paper} variant="outlined">
      <Box p={xs ? 0 : 1}>
        <Box>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<MapRounded />}
            className={styles.button}
            onClick={() => openMapImage(true)}
          >
            Seat Map
          </Button>
        </Box>
      </Box>
    </Paper>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'fixed',
    top: 300,
    right: 0,
    zIndex: 99,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRight: 0,
    boxShadow: '0 8px 16px rgb(0 0 0 / 10%)',
    [theme.breakpoints.down('xs')]: {
      top: 200
    }
  },
  button: {
    border: 'none',
    '&:hover': {
      border: 'none'
    },

    [theme.breakpoints.down('xs')]: {
      padding: 8
    },
    '& .MuiButton-label': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      fontFamily: 'Open Sans',
      fontWeight: 600,
      fontSize: 14,
      [theme.breakpoints.down('xs')]: {
        fontSize: 12
      },

      '& .MuiButton-startIcon': {
        margin: 0,

        '& svg': {
          [theme.breakpoints.down('xs')]: {
            fontSize: 18
          }
        }
      }
    }
  }
}));
