import React from 'react';
import {
  Box,
  Paper,
  Typography,
  makeStyles,
  colors,
  Grid,
  Button,
  IconButton,
  Avatar,
  lighten,
  darken
} from '@material-ui/core';
import clsx from 'clsx';
import {
  ZoomOutMapRounded,
  MapRounded,
  InfoOutlined
} from '@material-ui/icons';
import { v4 as uuid } from 'uuid';

export default function SeatCountSelector({
  handleSeatCountClick,
  activeSeatCountIndex,
  openMapImage
}) {
  const styles = useStyles();

  const seatCountList = [
    { name: 'item1' },
    { name: 'item2' },
    { name: 'item3' },
    { name: 'item4' },
    { name: 'item5' },
    { name: 'item6' },
    { name: 'item7' },
    { name: 'item8' }
  ];

  return (
    <Paper className={styles.paper} variant="outlined">
      <Box p={3} pb={0}>
        <Box textAlign="center">
          <Typography
            variant="h5"
            color="textPrimary"
            className={styles.sectionTitle}
          >
            How many seats?
          </Typography>
        </Box>

        <Box mt={1} textAlign="center">
          <Button
            disableElevation
            size="small"
            variant="text"
            color="primary"
            startIcon={<MapRounded />}
            className={styles.seatMapButton}
            onClick={openMapImage}
          >
            View Seat Map
          </Button>
        </Box>
      </Box>
      <Box p={3}>
        <Paper variant="outlined">
          <Box p={2} pb={0} textAlign="center">
            <Typography variant="subtitle1" className={styles.infoText}>
              <InfoOutlined />
              Tap to select.
            </Typography>
          </Box>

          <Box p={2} display="flex" justifyContent="center" alignItems="center">
            <Grid container spacing={2} justify="center">
              {seatCountList.map((item, index) => (
                <Grid item xs="auto" sm="auto" align="center" key={uuid()}>
                  <Avatar
                    variant="rounded"
                    className={clsx(
                      styles.seatCountButton,
                      index == activeSeatCountIndex
                        ? styles.seatCountSelected
                        : ''
                    )}
                    onClick={() => handleSeatCountClick(index)}
                  >
                    {index + 1}
                  </Avatar>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Paper>
      </Box>
    </Paper>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    height: '100%'
  },
  sectionTitle: {
    fontFamily: 'Raleway',
    fontSize: 24,
    fontWeight: 700
  },

  infoText: {
    fontFamily: 'Open Sans',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.2,
    color: theme.palette.success.main,

    '& .MuiSvgIcon-root': {
      marginBottom: -4,
      marginRight: 5,
      fontSize: 20
    }
  },
  seatMapButton: {
    textDecoration: 'underline',
    fontFamily: 'Open Sans',
    fontWeight: 600,
    fontSize: 14,

    '&:hover': {
      textDecoration: 'underline'
    }
  },
  seatCountButton: {
    backgroundColor: 'transparent',
    border: `1px solid  ${theme.palette.divider}`,
    color: colors.grey[600],
    cursor: 'pointer',
    fontFamily: 'Open Sans',
    boxShadow: '0 4px 8px rgb(0 0 0 / 10%)',
    transition: 'all 0.2s ease',
    fontWeight: 600,

    '&:hover': {
      backgroundColor: theme.palette.divider,
      boxShadow: 'none'
    }
  },

  seatCountSelected: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    border: `1px solid ${theme.palette.primary.main}`,
    pointerEvents: 'none'
  }
}));
