import React from 'react';
import {
  Box,
  Typography,
  Button,
  makeStyles,
  Grid,
  colors
} from '@material-ui/core';
import { NotificationsActiveOutlined, InfoOutlined } from '@material-ui/icons';

export default function NoSeats({
  activeSeatCountIndex,
  handleSubscribeClick
}) {
  const styles = useStyles();

  return (
    <Box pt={8} pb={8} pl={2} pr={2}>
      <Grid container justify="center">
        <Grid item xs={12} sm={8} md={6} lg={4}>
          <Box textAlign="center">
            <InfoOutlined className={styles.infoIcon} />
          </Box>
          <Box textAlign="center" mt={2}>
            <Typography variant="h4" className={styles.sectionTitle}>
              {activeSeatCountIndex == 0
                ? 'Sorry, 1 seat purchases are currently unavailable.'
                : 'No seats available. Please check your selection and try again.'}
            </Typography>
          </Box>
          <Box textAlign="center" mt={2}>
            <Button
              disableElevation
              startIcon={<NotificationsActiveOutlined />}
              variant="contained"
              size="large"
              color="primary"
              className={styles.notifyButton}
              onClick={handleSubscribeClick}
            >
              <span>GET NOTIFIED</span>
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

const useStyles = makeStyles(theme => ({
  sectionTitle: {
    fontFamily: 'Raleway',
    fontSize: 24,
    fontWeight: 700
  },

  infoIcon: {
    fontSize: 48,
    color: colors.grey[500]
  },
  notifyButton: {
    borderRadius: 30
  }
}));
