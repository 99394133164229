import React from 'react';
import {
  Paper,
  Typography,
  makeStyles,
  Box,
  Grid,
  colors,
  useTheme,
  useMediaQuery,
  Avatar,
  Divider,
  Button,
  Chip,
  fade,
  lighten,
  darken
} from '@material-ui/core';
import moment from 'moment';
import tConv24 from 'src/utils/utils';
import {
  ReportProblemOutlined,
  ConfirmationNumberOutlined,
  TouchApp,
  AccountBalanceRounded,
  AttachMoneyRounded,
  DoneAllRounded
} from '@material-ui/icons';
import clsx from 'clsx';

export default function Transaction({
  transaction,
  customerTransactionHistory,
  index
}) {
  const styles = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Paper elevation={6} variant="outlined" className={clsx(styles.paper)}>
      {/* {transaction.eventCode} */}
      <Box p={xs ? 2 : 2} className={styles.transWrapper}>
        <Box>
          <Avatar variant="rounded" className={styles.avatar}>
            <AccountBalanceRounded className={styles.transIcon} />
          </Avatar>
        </Box>

        <Box>
          <Box>
            <Typography
              variant="h6"
              color="textPrimary"
              className={styles.transName}
            >
              {transaction.numSeats}{' '}
              {transaction.numSeats == 1 ? 'Seat' : 'Seats'} in Section{' '}
              {transaction.seatBlock[0].section_name}
            </Typography>
          </Box>

          <Box>
            <Typography variant="subtitle2" className={styles.transEventName}>
              {
                customerTransactionHistory.events[transaction.eventCode]
                  ?.description
              }
            </Typography>
          </Box>

          <Box>
            <Typography variant="subtitle2" className={styles.transMeta}>
              Reservation Date :{' '}
              {new Date(transaction.dateCreated).toLocaleDateString()}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2" className={styles.transMeta}>
              Reservation #{transaction.reserveOrderNumber}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2" className={styles.transMeta}>
              Game Date :{' '}
              {moment(
                customerTransactionHistory.events[transaction.eventCode]?.date
              ).format('MM/DD/yyyy')}
            </Typography>
          </Box>

          {xs && (
            <Box className={styles.mobileInfoRow}>
              <Chip
                size="medium"
                avatar={
                  <Avatar>
                    <AttachMoneyRounded />
                  </Avatar>
                }
                label={transaction.totalCost.toFixed(2)}
                className={styles.priceChip}
              />

              {transaction.purchaseOrderNumber == 'NONE' ? (
                <Chip
                  size="medium"
                  avatar={
                    <Avatar variant="rounded">
                      <ReportProblemOutlined />
                    </Avatar>
                  }
                  label="PENDING"
                  className={clsx(styles.statusChip, styles.warningChip)}
                />
              ) : (
                <Chip
                  size="medium"
                  avatar={
                    <Avatar variant="rounded">
                      <DoneAllRounded />
                    </Avatar>
                  }
                  label="AVAILABLE"
                  className={clsx(styles.statusChip, styles.successChip)}
                />
              )}
            </Box>
          )}
        </Box>

        {!xs && (
          <>
            <Box className={styles.infoCol}>
              <Box>
                <Chip
                  size="medium"
                  avatar={
                    <Avatar>
                      <AttachMoneyRounded />
                    </Avatar>
                  }
                  label={transaction.totalCost.toFixed(2)}
                  className={styles.priceChip}
                />
              </Box>
            </Box>

            <Box className={styles.infoCol}>
              <Box>
                {transaction.purchaseOrderNumber == 'NONE' ? (
                  <Chip
                    size="medium"
                    avatar={
                      <Avatar variant="rounded">
                        <ReportProblemOutlined />
                      </Avatar>
                    }
                    label="PENDING"
                    className={clsx(styles.statusChip, styles.warningChip)}
                  />
                ) : (
                  <Chip
                    size="medium"
                    avatar={
                      <Avatar variant="rounded">
                        <DoneAllRounded />
                      </Avatar>
                    }
                    label="AVAILABLE"
                    className={clsx(styles.statusChip, styles.successChip)}
                  />
                )}
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Paper>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    height: '100%',
    transition: 'all 0.3s ease',
    position: 'relative',
    border: 'none',
    border: `none`

    // '&:hover': {
    //   transform: 'translateY(-5px)',
    //   boxShadow: '0 8px 16px rgb(0 0 0 / 10%)',
    //   border: `1px solid ${theme.palette.divider}`
    // }
  },

  transWrapper: {
    display: 'grid',
    gridTemplateColumns: '60px 1fr 1fr 1fr',
    alignItems: 'center',

    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '45px 1fr'
    }
  },
  transName: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 20,
    lineHeight: 1.2,

    [theme.breakpoints.down('sm')]: {
      fontSize: 16
    }
  },

  transEventName: {
    fontFamily: 'Open Sans',
    fontSize: 14,
    fontWeight: 700,
    lineHeight: 1.4,
    color: colors.grey[700],

    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  },

  transMeta: {
    fontFamily: 'Open Sans',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.4,
    color: colors.grey[600],

    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  },

  avatar: {
    backgroundColor: 'transparent',
    border: `1px solid ${colors.grey[400]}`,
    marginTop: 5,

    [theme.breakpoints.down('sm')]: {
      width: 35,
      height: 35
    }
  },
  transIcon: {
    color: colors.grey[600]
  },
  infoCol: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',

    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start'
    }
  },

  mobileInfoRow: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 8
  },

  priceChip: {
    backgroundColor: 'transparent',
    border: `1px solid ${fade(colors.grey[600], 0.4)}`,
    height: 36,
    // width: 200,
    paddingLeft: 30,
    borderRadius: 6,
    paddingLeft: 0,
    justifyContent: 'flex-start',
    overflow: 'hidden',

    [theme.breakpoints.down('sm')]: {
      height: 24
    },

    '& .MuiAvatar-root': {
      marginLeft: 0,
      marginRight: 4,
      backgroundColor: fade(colors.grey[600], 0.2),
      borderRadius: 0,
      height: '100%',
      width: 32,
      borderRight: `1px solid ${fade(colors.grey[600], 0.4)}`,

      [theme.breakpoints.down('sm')]: {
        width: 22
      }
    },

    '& .MuiChip-label': {
      fontSize: 18,
      fontWeight: 600,
      fontFamily: 'Open Sans',
      color: colors.grey[600],
      paddingLeft: 2,

      [theme.breakpoints.down('sm')]: {
        fontSize: 14
      }
    },

    '& .MuiSvgIcon-root': {
      color: colors.grey[600]
    }
  },

  statusChip: {
    height: 34,
    borderRadius: 6,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      height: 22,
      marginLeft: 16,
      width: 'fit-content'
    },

    '& .MuiChip-label': {
      fontSize: 16,
      fontWeight: 600,
      fontFamily: 'Poppins',

      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
        fontFamily: 'Open Sans'
      }
    },

    '& .MuiAvatar-root': {
      width: 24,
      height: 24,

      [theme.breakpoints.down('sm')]: {
        width: 18,
        height: '100%',
        marginLeft: 0,
        borderRadius: 0
      }
    },
    '& .MuiSvgIcon-root': {
      fontSize: 20,
      [theme.breakpoints.down('sm')]: {
        fontSize: 14
      }
    }
  },

  warningChip: {
    backgroundColor: fade(theme.palette.warning.main, 0.2),
    border: `1px solid ${theme.palette.warning.main}`,
    '& .MuiChip-label': {
      color: darken(theme.palette.warning.main, 0.5)
    },

    '& .MuiAvatar-root': {
      backgroundColor: fade(theme.palette.warning.main, 0.5),

      [theme.breakpoints.down('sm')]: {
        borderRight: `1px solid ${theme.palette.warning.main}`
      }
    },
    '& .MuiSvgIcon-root': {
      color: darken(theme.palette.warning.main, 0.5)
    }
  },

  successChip: {
    backgroundColor: fade(theme.palette.success.main, 0.2),
    border: `1px solid ${theme.palette.success.main}`,

    '& .MuiChip-label': {
      color: darken(theme.palette.success.main, 0.5)
    },

    '& .MuiAvatar-root': {
      backgroundColor: fade(theme.palette.success.main, 0.5),

      [theme.breakpoints.down('sm')]: {
        borderRight: `1px solid ${theme.palette.success.main}`
      }
    },
    '& .MuiSvgIcon-root': {
      color: darken(theme.palette.success.main, 0.5)
    }
  }
}));
