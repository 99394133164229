import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Paper,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  colors
} from '@material-ui/core';
import {
  AccountBalanceWalletOutlined,
  MonetizationOnOutlined,
  CreditCardOutlined
} from '@material-ui/icons';

export default function BusinessInfo({
  seat,
  customerCredit,
  activeSeatCountIndex,
  outstandingAmount,
  hasOutstanding,
  remainingAmount
}) {
  const styles = useStyles();

  return (
    <Paper variant="outlined">
      <Box>
        <List dense>
          <ListItem className={styles.listItem}>
            <ListItemIcon className={styles.listItemIcon}>
              <AccountBalanceWalletOutlined />
            </ListItemIcon>
            <ListItemText
              primary="Available Credit"
              className={styles.listItemText}
            />
            <ListItemSecondaryAction>
              <Typography variant="subtitle2" className={styles.orderInfo}>
                <span className={styles.infoAmount}>
                  ${' '}
                  {Number(
                    customerCredit?.creditAvailable['$numberDecimal']
                  ).toFixed(2)}
                </span>
              </Typography>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem className={styles.listItem}>
            <ListItemIcon className={styles.listItemIcon}>
              <MonetizationOnOutlined />
            </ListItemIcon>
            <ListItemText
              primary="Total Seat Cost"
              className={styles.listItemText}
            />
            <ListItemSecondaryAction>
              <Typography variant="subtitle2" className={styles.orderInfo}>
                <span className={styles.infoAmount}>
                  $ {(seat.price * (activeSeatCountIndex + 1)).toFixed(2)}
                </span>
              </Typography>
            </ListItemSecondaryAction>
          </ListItem>

          {!hasOutstanding && (
            <ListItem className={styles.listItem}>
              <ListItemIcon className={styles.listItemIcon}>
                <CreditCardOutlined />
              </ListItemIcon>
              <ListItemText
                primary="Balance After Purchase"
                className={styles.listItemText}
              />
              <ListItemSecondaryAction>
                <Typography variant="subtitle2" className={styles.orderInfo}>
                  <span className={styles.infoAmount}>$ {remainingAmount}</span>
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
          )}

          {hasOutstanding && (
            <ListItem className={styles.listItem}>
              <ListItemIcon className={styles.listItemIcon}>
                <CreditCardOutlined />
              </ListItemIcon>
              <ListItemText
                primary="Balance Due"
                className={styles.listItemText}
              />
              <ListItemSecondaryAction>
                <Typography variant="subtitle2" className={styles.orderInfo}>
                  <span className={styles.finalAmount}>
                    $ ({outstandingAmount})
                  </span>
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
          )}
        </List>
      </Box>
    </Paper>
  );
}

const useStyles = makeStyles(theme => ({
  listItem: {
    width: 'fit-content',
    padding: '0px 16px'
  },

  listItemIcon: {
    minWidth: 32,

    '& .MuiSvgIcon-root': {
      color: colors.grey[600]
    }
  },

  listItemText: {
    '& .MuiListItemText-primary': {
      fontFamily: 'Poppins',
      fontSize: 14,
      fontWeight: 500,
      color: colors.grey[600]
    }
  },

  infoAmount: {
    fontFamily: 'Open Sans',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.4,
    color: colors.grey[600]
  },

  finalAmount: {
    fontFamily: 'Open Sans',
    color: theme.palette.error.main,
    fontWeight: 500,
    fontSize: 16
  }
}));
