import React from 'react';
import { LaunchRounded } from '@material-ui/icons';

import BannerImage from '../../images/rangers/banner4.png';

const LOGIN_IMAGE = require('../../images/rangers/login.png');
const SELECT_GAMES_IMAGE = require('../../images/rangers/select_games.png');
const GET_TICKETS_IMAGE = require('../../images/rangers/get_tickets.png');

export default {
  THEME: 'primary',
  THEME_NAME: 'rangers',
  logo: 'https://ss-leagues.s3.amazonaws.com/NHL/rangers-logo2-aug21.png',
  seatMap:
    'https://ss-leagues.s3.amazonaws.com/SEATMAPS/Rangers_Map_Sep_21.PNG',
  siteTitle: 'NEW YORK RANGERS',
  teamName: 'New York Rangers',
  teamNameShort: 'NYR',
  testAccountNumber: '31383182',
  development: process.env.NODE_ENV == 'development' ? true : false,
  SUBDOMAIN: 'rangersflex',
  ACCOUNT_MANAGER_LINK: 'https://am.ticketmaster.com/msg/',
  API_BASE_URL: 'https://rangersapi.seasonshare.com',
  TM_LOGIN_URI: 'https://am.ticketmaster.com/msg/',
  // API_BASE_URL: 'http://localhost:3001',
  IMAGE_BASE_URL: 'https://ss-leagues.s3.amazonaws.com/NHL/',
  BACKGROUND_IMAGE_NAME: 'rangers-banner2-aug21.png',
  BACKGROUND_IMAGE_FILE: BannerImage,
  WHITE_LABEL_TEAM_ID: 134,
  PHONE_NUMBER: '212-465-6073',
  EMAIL: 'seasonsubscriptions@msg.com',
  V2_FLEX_URL: 'https://flex.seasonshare.com/rangers/landing',
  banner: {
    choose: 'EVENT SELECTION',
    desc: 'Redeem your account credit for upcoming events. '
  },

  BANNER_TEXT: {
    home: {
      title: '',
      subtitle: ''
    },
    thankYou: {
      title: '',
      subtitle: "We're excited to have you with us this season."
    }
  },

  BANNER_OVERLAY: 0,
  THANK_YOU_IMAGE: BannerImage,

  announcementText: '',

  EXTRA_NAV_LINKS: [
    {
      label: 'NY Knicks Flex Bank',
      icon: <LaunchRounded />,
      url: 'https://knicksflex.seasonshare.com?login=true'
    }
  ],

  steps: [
    {
      sl: 1,
      title: 'LOGIN TO ACCOUNT MANAGER',
      getSubtitle: teamName =>
        `Connect your ${teamName} account using our secure Ticketmaster login.`,
      image: LOGIN_IMAGE,
      startButton: false
    },

    {
      sl: 2,
      title: 'SELECT YOUR GAMES',
      getSubtitle: teamName =>
        `Select the games you wish to attend based on the amount of credit you have available.`,
      image: SELECT_GAMES_IMAGE,
      startButton: false
    },

    {
      sl: 3,
      title: 'GET YOUR TICKETS',
      getSubtitle: teamName =>
        `Tickets will be available in your Account Manager within 24 hours of redemption. Reach out to Live Fan Support for any questions.`,
      image: GET_TICKETS_IMAGE,
      startButton: true
    }
  ],

  faqs: [
    {
      question: 'How does the Rangers Flex work?',
      answer:
        'The Rangers Flex allows fans to allocate game credits to specific events from the upcoming schedule. Simply log in to your New York Rangers Account Manager and select games from your available account credit.'
    },
    {
      question: 'How do I get more credit?',
      answer:
        'Reach out to your New York Rangers Account Rep and inquire about the Rangers Flex program.'
    },
    {
      question: 'Do I have to select all my games now?',
      answer:
        'No. You have the opportunity to allocate credit throughout the season. However, please note that prices are subject to change.'
    },
    {
      question: 'Can I choose seats throughout the venue?',
      answer: 'Yes, you can choose from all current available inventory.'
    },
    {
      question: 'Where are event tickets delivered?',
      answer:
        'Event tickets are delivered directly within your New York Rangers Account Manager within 24 hours of selection.'
    }
  ]
};
