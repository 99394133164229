import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import configureStore from './state/configureStore';
import * as serviceWorker from './serviceWorker';
import App from './App';
import { CookiesProvider } from 'react-cookie';
import { CrispProvider } from 'src/context/CrispContext';

const store = configureStore();
const rootElement = document.getElementById('root');

ReactDOM.render(
  <Provider store={store}>
    <CookiesProvider>
      <BrowserRouter>
        <CrispProvider>
          <App />
        </CrispProvider>
      </BrowserRouter>
    </CookiesProvider>
  </Provider>,
  rootElement
);

serviceWorker.unregister();
