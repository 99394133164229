import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TablePagination,
  Typography,
  Box,
  Paper,
  Divider,
  makeStyles,
  Chip,
  Collapse,
  IconButton
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import { v4 as uuid } from 'uuid';

const useStyles = makeStyles(theme => ({
  mainRow: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));

const TableRows = ({
  _id,
  startingBalance,
  endingBalance,
  holdOrderNumber,
  numSeats,
  priceCode,
  purchaseOrderNumber,
  reserveOrderNumber,
  sectionName,
  soldDate,
  totalCost,
  status,
  seatBlock,
  dateCreated
}) => {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const classes = useStyles();
  const history = useHistory();

  return (
    <React.Fragment>
      <TableRow
        hover
        className={classes.mainRow}
        onClick={() => {
          history.push(`/portal/transactions-details?id=${_id}`);
        }}
      >
        {/* Starting balance */}
        {/* <TableCell>
          <Typography variant="subtitle2" color="textSecondary">
            {startingBalance ? startingBalance.toFixed(2) : 'Not available'}
          </Typography>
        </TableCell> */}

        {/* Ending balance */}
        {/* <TableCell>
          <Typography variant="subtitle2" color="textSecondary">
            {endingBalance ? endingBalance.toFixed(2) : 'Not available'}
          </Typography>
        </TableCell> */}

        {/* Hold order no */}
        {/* <TableCell>
          <Typography variant="subtitle2" color="textSecondary">
            {holdOrderNumber ? holdOrderNumber : 'Not available'}
          </Typography>
        </TableCell> */}

        {/*  Number of seats */}
        <TableCell>
          <Typography variant="subtitle2" color="textSecondary">
            {numSeats ? numSeats : 'Not available'}
          </Typography>
        </TableCell>

        {/* Section name */}
        <TableCell>
          <Typography variant="subtitle2" color="textSecondary">
            {sectionName ? sectionName : 'Not available'}
          </Typography>
        </TableCell>

        {/* Total cost */}
        <TableCell>
          <Typography variant="subtitle2" color="textSecondary">
            {totalCost ? '$ ' + totalCost.toFixed(2) : 'Not available'}
          </Typography>
        </TableCell>

        {/* created date */}
        <TableCell>
          <Typography variant="subtitle2" color="textSecondary">
            {dateCreated
              ? moment(dateCreated).format('DD-MMM-YYYY')
              : 'Not available'}
          </Typography>
        </TableCell>

        {/* Seat block */}
        <TableCell>
          {seatBlock.length > 0 && (
            <IconButton
              size="small"
              onClick={event => {
                event.stopPropagation();
                setCollapseOpen(!collapseOpen);
              }}
            >
              {collapseOpen ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
          )}
        </TableCell>

        {/* Price code */}
        {/* <TableCell>
          <Typography variant="subtitle2" color="textSecondary">
            {priceCode ? priceCode : 'Not available'}
          </Typography>
        </TableCell> */}

        {/* Purchase order no */}
        {/* <TableCell>
          <Typography variant="subtitle2" color="textSecondary">
            {purchaseOrderNumber ? purchaseOrderNumber : 'Not available'}
          </Typography>
        </TableCell> */}

        {/* Reserve order no */}
        {/* <TableCell>
          <Typography variant="subtitle2" color="textSecondary">
            {reserveOrderNumber ? reserveOrderNumber : 'Not available'}
          </Typography>
        </TableCell> */}

        {/* Sold Date */}
        {/* <TableCell>
          <Typography variant="subtitle2" color="textSecondary">
            {soldDate
              ? moment(soldDate).format('YYYY-MMM-DD')
              : 'Not available'}
          </Typography>
        </TableCell> */}

        {/* Status */}
        {/* <TableCell>
          <Chip
            color="primary"
            label={status === 'RESERVED' ? 'RESERVED' : 'SOLD'}
            size="small"
            style={{
              backgroundColor: status === 'RESERVED' ? 'red' : 'green'
            }}
          />
        </TableCell> */}
      </TableRow>

      {seatBlock.length > 0 && (
        <TableRow>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0, borderBottom: 'unset' }}
            colSpan={6}
          >
            <Collapse in={collapseOpen}>
              <Box m={4} mt={2} mb={2}>
                {/* <Typography variant="h4" color="textPrimary">
                  Seat block
                </Typography> */}
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Available seat</TableCell>
                      <TableCell>Price</TableCell>
                      <TableCell>Price code</TableCell>
                      <TableCell>Quality</TableCell>
                      <TableCell>Section name</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {seatBlock.map(
                      ({
                        available_seat,
                        price,
                        price_code,
                        quality,
                        section_name
                      }) => (
                        <TableRow key={uuid()}>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              color="textSecondary"
                            >
                              {available_seat
                                ? available_seat
                                : 'Not available'}
                            </Typography>
                          </TableCell>

                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              color="textSecondary"
                            >
                              {price ? price : 'Not available'}
                            </Typography>
                          </TableCell>

                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              color="textSecondary"
                            >
                              {priceCode ? price_code : 'Not available'}
                            </Typography>
                          </TableCell>

                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              color="textSecondary"
                            >
                              {quality ? quality : 'Not available'}
                            </Typography>
                          </TableCell>

                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              color="textSecondary"
                            >
                              {section_name ? section_name : 'Not available'}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
};

const TransactionTable = ({ transactions }) => {
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Paper>
      <Box p={3} pt={2} pb={2}>
        <Typography variant="h4" color="textPrimary">
          Transactions
        </Typography>
      </Box>
      <Divider />
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Number of seats</TableCell>
                <TableCell>Section name</TableCell>
                <TableCell>Total cost</TableCell>
                <TableCell>Created at</TableCell>
                <TableCell>Seat Block</TableCell>

                {/* <TableCell>Starting balance</TableCell>
                <TableCell>Ending balance</TableCell>
                <TableCell>Hold order no.</TableCell>
                <TableCell>Price code</TableCell>
                <TableCell>Purchase order no.</TableCell>
                <TableCell>Reserve order no.</TableCell>
                <TableCell>Sold date</TableCell>
                <TableCell>Status</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions.length > 0 &&
                transactions
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(transaction => (
                    <TableRows {...transaction} key={transaction._id} />
                  ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={transactions.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Box>
      </PerfectScrollbar>
    </Paper>
  );
};
export default TransactionTable;
