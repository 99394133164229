import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  Divider,
  Button,
  TextField,
  useMediaQuery,
  useTheme,
  Hidden
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles(theme => ({
  root: {},
  hover: {
    cursor: 'pointer'
  }
}));

const Results = ({ className, customers, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [page, setPage] = useState(0);
  const [searchedItem, setSearchedItem] = useState([]);
  const history = useHistory();

  const handleSelectAll = event => {
    let newSelectedCustomerIds;

    if (event.target.checked) {
      newSelectedCustomerIds = customers.map(customer => customer._id);
    } else {
      newSelectedCustomerIds = [];
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomerIds.indexOf(id);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds,
        id
      );
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(1)
      );
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1)
      );
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearcInputChange = (value, type) => {
    if (type === 'email') {
      let filtered = customers.filter(item => item.emailAddress === value);
      setSearchedItem(filtered);
    }
    if (type === 'account') {
      let filtered = customers.filter(item => item.accountNumber === value);
      setSearchedItem(filtered);
    }
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Box
        p={2}
        display="flex"
        alignItems={sm ? 'start' : 'center'}
        flexDirection={sm ? 'column' : 'row'}
      >
        <Box mb={sm ? 1 : 0}>
          <Typography variant="h4">Customers</Typography>
        </Box>
        <Hidden smDown>
          <Box flexGrow={1} />
        </Hidden>
        <Box
          display="flex"
          flexDirection={sm ? 'column' : 'row'}
          width={sm ? '100%' : 'inherit'}
        >
          <Box mr={sm ? 0 : 3} mb={sm ? 1 : 0} width="100%">
            <Autocomplete
              id="email-search"
              options={customers}
              getOptionLabel={option => option.emailAddress}
              clearOnBlur
              clearOnEscape
              selectOnFocus
              renderInput={params => (
                <TextField
                  {...params}
                  size="small"
                  label="Search by email"
                  variant="outlined"
                />
              )}
              onInputChange={(event, value) =>
                handleSearcInputChange(value, 'email')
              }
              style={{ width: sm ? '100%' : 250 }}
            />
          </Box>
          <Hidden smDown>
            <Divider orientation="vertical" flexItem />
          </Hidden>
          <Box ml={sm ? 0 : 3} width="100%">
            <Autocomplete
              id="account-search"
              options={customers}
              getOptionLabel={option => option.accountNumber}
              clearOnBlur
              clearOnEscape
              selectOnFocus
              renderInput={params => (
                <TextField
                  {...params}
                  size="small"
                  label="Search by account"
                  variant="outlined"
                />
              )}
              onInputChange={(event, value) =>
                handleSearcInputChange(value, 'account')
              }
              style={{ width: sm ? '100%' : 250 }}
            />
          </Box>
        </Box>
      </Box>
      <Divider />
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedCustomerIds.length === customers.length}
                    color="primary"
                    indeterminate={
                      selectedCustomerIds.length > 0 &&
                      selectedCustomerIds.length < customers.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell>Email Address</TableCell>
                <TableCell>Account Number</TableCell>
                <TableCell>Credit Available</TableCell>
                <TableCell>Created at</TableCell>
                <TableCell>Last login</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(searchedItem.length
                ? searchedItem
                : customers.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
              ).map(customer => (
                <TableRow
                  hover
                  classes={{ hover: classes.hover }}
                  key={customer._id}
                  selected={selectedCustomerIds.indexOf(customer._id) !== -1}
                  onClick={() =>
                    history.push(
                      `/portal/customer-details?account=${customer.accountNumber}`
                    )
                  }
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedCustomerIds.indexOf(customer._id) !== -1}
                      onChange={event => handleSelectOne(event, customer._id)}
                      value="true"
                    />
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2" color="textSecondary">
                      {customer.emailAddress}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2" color="textSecondary">
                      {customer.accountNumber}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography variant="subtitle2" color="textSecondary">
                      $
                      {Number(customer.creditAvailable.$numberDecimal)
                        .toFixed(2)
                        .toLocaleString()}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {moment(customer.createdAt).format('MM/DD/YYYY hh:mm A')}
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2" color="textSecondary">
                      {moment(customer.lastLogin).format('MM/DD/YYYY hh:mm A')}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={customers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired
};

export default Results;
