import React from 'react';
import '../views/css/home.scss';
function Accordion(props: any) {
    const { question, answer } = props
    const showMessage = true; 
    return (
        <div>
            <div className="" style={{ display: "flex",marginBottom: "", alignItems: "center", marginLeft: "3vw", width: "91%" }}>
                <div className="circle">
                </div>
                <h3 style={{ fontSize: "24px", fontWeight: 500 }}>{question} </h3>
            </div>
            {showMessage ? <p style={{ padding: "0px 3.0vw", width: "92%", fontSize: "20px", marginTop: "0" }}>{answer} </p> : ""}

        </div>
    )
}

export default Accordion