import * as types from './actionTypes';
import initialState from '../initialState';

const login = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGGED_IN:
      return { ...state, user: action.payload };
    default:
      return state;
  }
};

export default login;
