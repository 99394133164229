import apiClient from './config/apiClient';
import { authEndPoint } from './config/apiEndpoints';

export const authApi = {
  authenticateUser(user) {
    return apiClient.post(authEndPoint + '/login', user).then(response => {
      return response;
    });
  }
};
