import React, { useEffect } from 'react';
import '../views/css/loader.scss';
import { Box, makeStyles, CircularProgress } from '@material-ui/core';

export default function Loader() {
  const classes = useStyles();

  useEffect(() => {
    console.log('use loading');
  }, []);

  return (
    <Box className={classes.wrapper}>
      <CircularProgress className={classes.spinner} />
    </Box>
  );
}

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '100%',
    height: '100vh',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 9999,
    backgroundColor: 'rgba(0,0,0,0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  spinner: {
    fontSize: 32,
    color: theme.palette.primary.main
  }
}));
