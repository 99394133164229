import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import {
  Container,
  Grid,
  Box,
  Card,
  CardContent,
  Divider,
  Typography,
  Button,
  makeStyles
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import DashboardLayout from '../../../layouts/DashboardLayout';
import Page from '../../../components/Page';
import Blocks from './Blocks';
import ActivityDetails from './ActivityDetails';
import * as customerActions from '../../../state/customer/customerAction';
import * as eventActions from '../../../state/event/eventAction';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const TransactionsDetails = () => {
  const transactions = useSelector(state =>
    state.customers.transactions ? state.customers.transactions : {}
  );

  const activity = useSelector(state =>
    state.customers.activity ? state.customers.activity : []
  );

  const event = useSelector(state =>
    state.events.previewEvent ? state.events.previewEvent[0] : []
  );

  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    const customerId = params.get('id');
    localStorage.setItem('customerId', customerId);
    if (customerId) {
      dispatch(customerActions.loadTransactions(customerId));
    }
  }, []);

  useEffect(() => {
    if (Object.keys(transactions).length) {
      dispatch(
        customerActions.loadActivity(transactions.TeamAccount.accountNumber)
      );
      dispatch(eventActions.previewEvent(transactions.eventCode));
    }
  }, [transactions]);

  activity.sort(function(a, b) {
    var keyA = new Date(a.date),
      keyB = new Date(b.date);
    if (keyA > keyB) return -1;
    if (keyA < keyB) return 1;
    return 0;
  });

  return (
    <DashboardLayout>
      <Page className={classes.root} title="Transaction Details">
        <Container maxWidth={false}>
          <Blocks transactions={transactions} event={event} />
          <ActivityDetails activities={activity} />
        </Container>
      </Page>
    </DashboardLayout>
  );
};

export default TransactionsDetails;
