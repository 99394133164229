import React, { useState } from 'react';
import {
  THEME,
  BACKGROUND_IMAGE_FILE as heroImage,
  THANK_YOU_IMAGE,
  BANNER_TEXT,
  teamName,
  ACCOUNT_MANAGER_LINK
} from '../views/data/environment';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Typography,
  Grid,
  Container,
  makeStyles,
  Button,
  colors,
  darken
} from '@material-ui/core';
import ThankYouBanner from '../components/ThankYouBanner';
import clsx from 'clsx';
import {
  FiberManualRecordOutlined,
  FullscreenExit,
  HomeOutlined
} from '@material-ui/icons';
import tmIcon from '../images/tm-icon.png';
import AccountManagerButton from 'src/components/AccountManagerButton';

import './css/home.scss';
export default function ThankYou() {
  const history = useHistory();
  const classes = useStyles();

  return (
    <>
      {/* <div>
        <div
          id={THEME}
          style={{
            backgroundImage: `url(${THANK_YOU_IMAGE})`,
            height: '100vh'
          }}
        >
          <div className="text-col">
            <h1 className="header-text">THANK YOU! </h1>
            <p className="banner-paras">
              We're excited to have you with us this season.
            </p>
            <button
              className="btn main-btn"
              onClick={() => {
                history.push('/');
              }}
            >
              HOME
            </button>
          </div>
        </div>
      </div> */}

      <>
        <Box>
          <ThankYouBanner background={THANK_YOU_IMAGE} isPortalOpen={true} />
        </Box>
        <Box p={4} pb={24}>
          <Container>
            <Grid container justify="center">
              <Grid item xs={12} sm={12} md={7} lg={6}>
                <Box textAlign="center">
                  <Typography variant="h1" className={classes.title}>
                    {BANNER_TEXT.thankYou.title}
                  </Typography>
                </Box>
                <Box textAlign="center" mt={1}>
                  <Typography variant="subtitle1" className={classes.subtitle}>
                    {BANNER_TEXT.thankYou.subtitle}
                  </Typography>
                </Box>

                <Box
                  textAlign="center"
                  mt={1}
                  mb={2}
                  display="flex"
                  justifyContent="center"
                >
                  <Box className={classes.shapesWrapper}>
                    <span className={clsx(classes.shape, classes.shape1)} />
                    <FiberManualRecordOutlined className={classes.shapeIcon} />
                    <span className={clsx(classes.shape, classes.shape2)} />
                  </Box>
                </Box>

                <Box textAlign="center" mt={2}>
                  <Typography variant="body1" className={classes.paragraph}>
                    Please visit your {teamName} Account Manager to access
                    barcodes. Please allow up to 2 hours for barcodes to appear
                    in your account.
                  </Typography>
                </Box>

                <Box textAlign="center" mt={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Button
                        disableElevation
                        className={classes.button}
                        size="large"
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => history.push('/')}
                      >
                        {/* <span className={classes.buttonIconWrap}></span> */}
                        <HomeOutlined className={classes.buttonIcon} />
                        HOME
                      </Button>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <AccountManagerButton label="Account Manager" />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  title: {
    fontFamily: 'Raleway',
    fontSize: 48,
    fontWeight: 800,

    [theme.breakpoints.down('xs')]: {
      fontSize: 32
    }
  },
  subtitle: {
    fontFamily: 'Raleway',
    fontSize: 24,
    lineHeight: 1.2,
    fontWeight: 500,
    color: colors.grey[800],

    [theme.breakpoints.down('xs')]: {
      fontSize: 20
    }
  },
  paragraph: {
    fontFamily: 'Open Sans',
    fontSize: 18,
    color: colors.grey[800],

    [theme.breakpoints.down('xs')]: {
      fontSize: 16
    }
  },
  button: {
    // width: 200,
    fontSize: 16,
    borderRadius: 50,
    fontFamily: 'Open Sans',
    position: 'relative'
  },
  tmButton: {
    backgroundColor: '#026cdf',

    '&:hover': {
      backgroundColor: darken('#026cdf', 0.2)
    }
  },
  buttonIcon: {
    fontSize: 22,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: 15
  },
  tmIcon: {
    width: 10,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: 20
  },

  buttonIconWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    width: 25,
    height: 25,
    border: '1px solid white',
    marginRight: 5,
    position: 'absolute',
    left: 6,
    top: '50%',
    transform: 'translateY(-50%)'
  },

  shapesWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 190,
    height: 20
  },

  shape: {
    width: 50,
    height: 2,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 15
  },
  shape1: {
    marginRight: -4
  },
  shape2: {
    marginLeft: -4
  },
  shapeIcon: {
    color: theme.palette.primary.main,
    // marginLeft: 5,
    // marginRight: 5,
    fontSize: 18
    // transform: `rotate(50deg)`
  }
}));
