import React from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import Page from '../../../components/Page';
import AddEventCode from './AddEventCode';
import ProfileDetails from './ProfileDetails';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DashboardLayout from '../../../layouts/DashboardLayout';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const EventConfig = () => {
  const classes = useStyles();

  return (
    <DashboardLayout>
      <Page className={classes.root} title="Event">
        <ToastContainer />
        <Container maxWidth="lg">
          <h1>Event Code Setup</h1>
          <h2>Update events fans can redeem credits for</h2>
          <Grid container spacing={3}>
            <Grid item lg={4} md={6} xs={12}>
              <AddEventCode />
            </Grid>
            <Grid item lg={8} md={6} xs={12}>
              <ProfileDetails />
            </Grid>
          </Grid>
        </Container>
      </Page>
    </DashboardLayout>
  );
};

export default EventConfig;
