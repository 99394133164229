import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Container, Grid, makeStyles } from '@material-ui/core';
import Page from '../../components/Page';
import DashboardLayout from '../../layouts/DashboardLayout';
import axios from 'axios';
import { API_BASE_URL, WHITE_LABEL_TEAM_ID } from '../data/environment';
import RemindersTable from './RemindersTable';
import { getAllEventReminders } from '../../state/reminder/reminderAction';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const EventReminders = () => {
  // const customers = useSelector(state =>
  //   state.customers.customers ? state.customers.customers : []
  // );

  const dispatch = useDispatch();
  const classes = useStyles();
  const { reminders } = useSelector(state => state);
  // const [reminders, setReminders] = useState(null);

  useEffect(() => {
    dispatch(getAllEventReminders());
  }, []);

  // const getReminders = async () => {
  //   let endpoint = `${API_BASE_URL}/event/reminders`;
  //   let response = await axios.post(endpoint, { teamId: WHITE_LABEL_TEAM_ID });

  //   if (response.status === 200) {
  //     setReminders(response.data.eventReminders);

  //     console.log(response.data.eventReminders);
  //   }
  // };

  return (
    <DashboardLayout>
      <Page className={classes.root} title="Event reminders">
        <Container maxWidth={false}>
          <Grid container>
            <Grid item xs={12} lg={12}>
              {reminders && <RemindersTable reminders={reminders} />}
            </Grid>
          </Grid>
        </Container>
      </Page>
    </DashboardLayout>
  );
};

export default EventReminders;
