import {
  beginApiCall,
  apiCallError,
  endApiCall
} from '../apiStatus/apiStatusActions';
import { apiReponseError } from '../../api/config/apiRequestError';
import {
  API_BASE_URL,
  WHITE_LABEL_TEAM_ID
} from '../../views/data/environment';
import { toast } from 'react-toastify';
import axios from 'axios';
import * as types from './actionTypes';

export function getAllEventReminders() {
  let endpoint = `${API_BASE_URL}/event/reminders`;
  return async dispatch => {
    try {
      let response = await axios.post(endpoint, {
        teamId: WHITE_LABEL_TEAM_ID
      });
      if (response.status === 200) {
        dispatch({
          type: types.GET_ALL_REMINDERS,
          payload: response.data.eventReminders
        });
      }
    } catch (error) {
      toast.error('Something went wrong, please try again');
    }
  };
}
