import React, { useState, useEffect } from 'react';
import {
  Paper,
  Typography,
  makeStyles,
  Box,
  Grid,
  colors,
  useTheme,
  useMediaQuery,
  Avatar,
  Divider,
  Button
} from '@material-ui/core';
import moment from 'moment';
import tConv24 from 'src/utils/utils';
import {
  ReportProblemOutlined,
  ConfirmationNumberOutlined,
  TouchApp,
  WarningRounded
} from '@material-ui/icons';
import clsx from 'clsx';
import ConfirmOrderSidebar from 'src/components/ConfirmOrderSidebar';

export default function Seat({
  seat,
  handleLocationClick,
  getSeatNumString,
  activeSeatCountIndex,
  customerCredit,
  events,
  activeEventIndex,
  handleRegularPurchase
}) {
  const styles = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const [confirmOpen, setConfirmOpen] = useState(false);
  const handleConfirmDrawer = open => setConfirmOpen(open);

  const handleSeatClick = () => {
    handleConfirmDrawer(true);
  };

  return (
    <>
      {customerCredit?.creditAvailable['$numberDecimal'] &&
        (customerCredit?.creditAvailable['$numberDecimal'] >= 0 ||
          customerCredit?.creditAvailable['$numberDecimal'] <= 0) && (
          <ConfirmOrderSidebar
            open={confirmOpen}
            {...{
              seat,
              customerCredit,
              getSeatNumString,
              activeSeatCountIndex,
              handleConfirmDrawer,
              events,
              activeEventIndex,
              handleRegularPurchase,
              handleLocationClick
            }}
          />
        )}

      <Paper
        elevation={6}
        variant="outlined"
        className={clsx(styles.paper)}
        onClick={handleSeatClick}
      >
        <Box p={2} className={styles.seatWrapper}>
          <Box>
            <Avatar variant="rounded" className={styles.avatar}>
              <ConfirmationNumberOutlined className={styles.ticketIcon} />
            </Avatar>
          </Box>

          <Box>
            <Box>
              <Typography
                variant="h6"
                color="textPrimary"
                className={styles.seatName}
              >
                Section {seat.section_name}
                <span className={styles.seatNameDivider} />
                Row {seat.row_num}
              </Typography>
            </Box>

            <Box>
              <Typography variant="subtitle2" className={styles.seatMeta}>
                {getSeatNumString(seat.available_seat, seat.num_seats)}
              </Typography>
            </Box>

            <Box>
              <Typography variant="subtitle2" className={styles.seatMeta}>
                ${parseFloat(seat.price).toFixed(2)} each
              </Typography>
            </Box>

            <Box
              style={{ marginTop: 3 }}
              display="flex"
              justifyContent="space-between"
              className={styles.seatActionRow}
            >
              <Typography variant="subtitle2" className={styles.success}>
                ${(seat.price * (activeSeatCountIndex + 1)).toFixed(2)} Total
              </Typography>
            </Box>

            {customerCredit?.creditAvailable['$numberDecimal'] &&
              Number(seat.price * (activeSeatCountIndex + 1)) >
                Number(customerCredit?.creditAvailable['$numberDecimal']) && (
                <Box className={styles.seatActionRow}>
                  <Typography variant="subtitle2" className={styles.warning}>
                    <ReportProblemOutlined className={styles.warningIcon} />{' '}
                    Price exceeds available credit
                  </Typography>
                </Box>
              )}
          </Box>
        </Box>

        <Button
          size="small"
          variant="outlined"
          disableElevation
          color="primary"
          className={styles.selectButton}
          startIcon={<TouchApp />}
          onClick={handleSeatClick}
        >
          SELECT
        </Button>
      </Paper>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    cursor: 'pointer',
    height: '100%',
    transition: 'all 0.3s ease',
    boxShadow: '0 8px 16px rgb(0 0 0 / 10%)',
    borderRadius: 8,
    position: 'relative',

    '&:hover': {
      transform: 'translateY(-5px)',
      boxShadow: '0 8px 16px rgb(0 0 0 / 10%)'
    }
  },

  seatWrapper: {
    display: 'grid',
    gridTemplateColumns: '60px 1fr',
    alignItems: 'center'
  },
  seatName: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 20,
    lineHeight: 1.2,

    [theme.breakpoints.down('sm')]: {
      fontSize: 16
    }
  },

  seatNameDivider: {
    height: 20,
    marginLeft: 5,
    marginRight: 5,
    width: 2,
    backgroundColor: theme.palette.divider,
    display: 'inline-block',
    marginBottom: -3
  },

  seatMeta: {
    fontFamily: 'Open Sans',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.4,
    color: colors.grey[600],

    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  },

  avatar: {
    backgroundColor: 'transparent',
    border: `1px solid ${colors.grey[400]}`
  },
  ticketIcon: {
    color: colors.grey[600]
  },

  warning: {
    color: theme.palette.warning.main,
    fontFamily: 'Open Sans',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.1,
    marginTop: 0,
    marginLeft: -2,
    textAlign: 'left',
    marginBottom: 30
  },
  warningIcon: {
    marginRight: 1,
    marginBottom: -2,
    fontSize: 15
  },
  success: {
    color: theme.palette.success.main,
    fontFamily: 'Open Sans',
    fontSize: 16,
    fontWeight: 500,

    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  },

  selectButton: {
    borderRadius: 5,
    position: 'absolute',
    right: 10,
    bottom: 10,
    padding: '2px 8px',
    fontSize: 14,
    fontWeight: 600,
    fontFamily: 'Open Sans',

    '& .MuiButton-startIcon': {
      marginRight: 2
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: 12
    }
  }
}));
