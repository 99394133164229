import apiClient from './config/apiClient';
import {
  WHITE_LABEL_TEAM_ID,
  teamName,
  phoneNumber,
  email
} from '../views/data/environment';
import { eventEndPoint, eventSeatEndPoint } from './config/apiEndpoints';
import { deleteEvent } from '../state/event/eventAction';
import Swal from 'sweetalert2';

export const eventApi = {
  getAllEvents() {
    return apiClient.post('/v2/events').then(response => {
      return response;
    });
  },
  getAllFutureEvents() {
    return apiClient.post('/v2/future_events').then(response => {
      return response;
    });
  },
  previewEvent(eventCode) {
    const eventData = {
      teamId: WHITE_LABEL_TEAM_ID,
      eventCode: eventCode
    };
    return apiClient
      .post(eventEndPoint + '/preview', eventData)
      .then(response => {
        return response;
      });
  },
  saveEvent(eventCode, logoUrl) {
    const eventData = {
      teamId: WHITE_LABEL_TEAM_ID,
      eventCode: eventCode,
      logoUrl: logoUrl
    };
    return apiClient
      .post(eventEndPoint + '/preview/save', eventData)
      .then(response => {
        return response;
      });
  },
  getSections(eventTicketData) {
    const eventTicket = {
      numSeats: eventTicketData.numSeats,
      eventCode: eventTicketData.eventCode,
      teamId: WHITE_LABEL_TEAM_ID,
      accountId: eventTicketData.accountId
    };
    return apiClient
      .post(eventSeatEndPoint + '/sections', eventTicket)
      .then(response => {
        return response;
      })
      .catch(error => {
        error?.response?.status === 501 &&
          Swal.fire({
            icon: 'error',
            title: 'Not eligible to redeem credit',
            html: `<p class="modal-text" >To enroll in the ${teamName} Flex program, please contact Ticket Sales at <a href="tel:${phoneNumber}" class="modal-phone">${phoneNumber}</a>.</p>`,
            allowOutsideClick: false,
            focusConfirm: true
          }).then(result => {
            if (result.isConfirmed) {
              window.location.href = '/';
            }
          });
      });
  },
  bookTicket(confirmTicket) {
    const confirmTicketData = {
      PK_ID: confirmTicket.PK_ID,
      numSeats: confirmTicket.numSeats,
      eventCode: confirmTicket.eventCode,
      sectionName: confirmTicket.sectionName,
      teamId: WHITE_LABEL_TEAM_ID,
      accountId: confirmTicket.accountId,
      rowNum: confirmTicket.rowNum,
      ticketTypeCode: confirmTicket.ticketTypeCode
    };
    return apiClient
      .post(eventSeatEndPoint + '/checkout', confirmTicketData)
      .then(response => {
        return response;
      });
  },
  deleteEvent(eventId) {
    const eventData = {
      eventId: eventId
    };
    return apiClient
      .post(eventEndPoint + '/delete', eventData)
      .then(response => {
        return response;
      });
  }
};
