import coyotes from './env.coyotes';
import roadrunners from './env.roadrunners';
import jaguars from './env.jaguars';
import ohiostate from './env.ohiostate';
import newjersey from './env.newjersey';
import rangers from './env.rangers';
import knicks from './env.knicks';

// const Helper = arg =>
//   process.env.REACT_APP_TYPE === 'coyotes' ? coyotes[arg] : roadrunners[arg];

const Helper = arg => {
  let appType = process.env.REACT_APP_TYPE;

  switch (appType) {
    case 'coyotes':
      return coyotes[arg];
    case 'roadrunners':
      return roadrunners[arg];
    case 'jaguars':
      return jaguars[arg];
    case 'ohiostate':
      return ohiostate[arg];
    case 'newjersey':
      return newjersey[arg];
    case 'rangers':
      return rangers[arg];
    case 'knicks':
      return knicks[arg];
  }
};

export const THEME = Helper('THEME');
export const THEME_NAME = Helper('THEME_NAME');
export const logo = Helper('logo');
export const siteTitle = Helper('siteTitle');
export const teamName = Helper('teamName');
export const seatMap = Helper('seatMap');
export const development = Helper('development');
export const teamNameShort = Helper('teamNameShort');
export const testAccountNumber = Helper('testAccountNumber');
export const SUBDOMAIN = Helper('SUBDOMAIN');
export const ACCOUNT_MANAGER_LINK = Helper('ACCOUNT_MANAGER_LINK');
export const API_BASE_URL = Helper('API_BASE_URL');
export const IMAGE_BASE_URL = Helper('IMAGE_BASE_URL');
export const BACKGROUND_IMAGE_FILE = Helper('BACKGROUND_IMAGE_FILE');
export const BACKGROUND_IMAGE_NAME = Helper('BACKGROUND_IMAGE_NAME');
export const WHITE_LABEL_TEAM_ID = Helper('WHITE_LABEL_TEAM_ID');
export const banner = Helper('banner');
export const events = Helper('events');
export const totalPrice = Helper('totalPrice');
export const faqs = Helper('faqs');
export const email = Helper('EMAIL');
export const phoneNumber = Helper('PHONE_NUMBER');
export const BANNER_TEXT = Helper('BANNER_TEXT');
export const BANNER_OVERLAY = Helper('BANNER_OVERLAY');
export const THANK_YOU_IMAGE = Helper('THANK_YOU_IMAGE');
export const LOGIN_IMAGE = Helper('LOGIN_IMAGE');
export const SELECT_GAMES_IMAGE = Helper('SELECT_GAMES_IMAGE');
export const GET_TICKETS_IMAGE = Helper('GET_TICKETS_IMAGE');
export const TM_LOGIN_URI = Helper('TM_LOGIN_URI');
export const announcementText = Helper('announcementText');
export const steps = Helper('steps');
export const EXTRA_NAV_LINKS = Helper('EXTRA_NAV_LINKS');
export const V2_FLEX_URL = Helper('V2_FLEX_URL');
