import React from 'react';
import {
  Box,
  Grid,
  Paper,
  colors,
  makeStyles,
  Button
} from '@material-ui/core';
import { DoneAll, CloseRounded } from '@material-ui/icons';
import clsx from 'clsx';

export default function ActionButtons({
  handleConfirmDrawer,
  handlePurchaseConfirm
}) {
  const styles = useStyles();

  return (
    <Paper variant="outlined">
      <Box p={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                disableElevation
                size="small"
                startIcon={<DoneAll />}
                className={styles.button}
                onClick={handlePurchaseConfirm}
              >
                Confirm
              </Button>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                disableElevation
                size="small"
                startIcon={<CloseRounded />}
                className={clsx(styles.button, styles.cancelButton)}
                onClick={() => handleConfirmDrawer(false)}
              >
                Cancel
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}

const useStyles = makeStyles(theme => ({
  button: {
    position: 'relative',
    paddingTop: 8,
    paddingBottom: 8,
    '& .MuiButton-startIcon': {
      position: 'absolute',
      top: '50%',
      left: 15,
      transform: 'translateY(-50%)'
    }
  },
  cancelButton: {
    backgroundColor: colors.grey['300'],
    color: colors.grey['600'],

    '&:hover': {
      backgroundColor: colors.grey['400'],
      color: colors.grey['700']
    }
  }
}));
