import React from 'react';
import {
  Box,
  Grid,
  TextField,
  Button,
  makeStyles,
  colors,
  useTheme,
  useMediaQuery,
  Typography,
  lighten,
  darken,
  Paper
} from '@material-ui/core';
import { useFormik } from 'formik';
import clsx from 'clsx';
import * as yup from 'yup';
import { DoneAll, CloseRounded } from '@material-ui/icons';
import cardImages from 'src/images/cc-images.png';
import { Alert } from '@material-ui/lab';

export default function PaymentForm({ outstandingAmount }) {
  const styles = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Paper variant="outlined">
      <Box p={2}>
        <Box
          mb={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            className={styles.sectionTitle}
            variant="h6"
            color="textPrimary"
          >
            Payment Method
          </Typography>
          <img className={styles.cardImages} src={cardImages} alt="" />
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                label="Card Number"
              />
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Box>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                label="Card Expiry"
              />
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Box>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                label="Card CVC"
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box>
              <Alert
                className={styles.alert}
                severity="info"
                classes={{
                  message: styles.alertMessage,
                  icon: styles.alertIcon
                }}
              >
                <Typography
                  variant="subtitle1"
                  className={styles.alertMessageText}
                >
                  You will be charged <b>${outstandingAmount}</b> to cover the
                  outstanding cost of these seats.
                </Typography>
              </Alert>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}

const useStyles = makeStyles(theme => ({
  sectionTitle: {
    fontSize: 16,
    fontFamily: 'Raleway',
    fontWeight: 700
  },
  cardImages: {
    width: 130,
    height: '100%'
  },

  alert: {
    // width: '100%'
    // padding: '4px 4px',
    backgroundColor: `${lighten(theme.palette.info.main, 0.95)} !important`
  },
  alertMessage: {
    padding: 0,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center'
  },
  alertMessageText: {
    lineHeight: 1.4,
    letterSpacing: '0',
    fontSize: 12
  },
  alertIcon: {
    '& .MuiSvgIcon-root': {
      color: darken(theme.palette.info.main, 0.5)
    }
  }
}));
