const LOGIN_IMAGE = require('../../images/ohiostate/login.png');
const SELECT_GAMES_IMAGE = require('../../images/coyotes/select_games.png');
const GET_TICKETS_IMAGE = require('../../images/coyotes/get_tickets.png');

export default {
  THEME: 'primary',
  THEME_NAME: 'ohiostate',
  logo: 'https://ss-leagues.s3.amazonaws.com/NCAA/ohio-state-buckeyes.png',
  seatMap: 'https://ss-stadiums.s3-us-west-1.amazonaws.com/tucson-seat-map.png',
  siteTitle: 'OHIO STATE',
  teamName: 'Ohio State',
  teamNameShort: 'ohiostate',
  testAccountNumber: '31383182',
  development: false,
  SUBDOMAIN: 'ohiostate',
  ACCOUNT_MANAGER_LINK: 'https://am.ticketmaster.com/eva/#/',
  TM_LOGIN_URI: '',
  API_BASE_URL: 'https://roadrunners-prod.herokuapp.com',
  // API_BASE_URL: 'http://localhost:3001',
  IMAGE_BASE_URL: 'https://s3.us-east-2.amazonaws.com/',
  BACKGROUND_IMAGE_NAME: 'banner2.png',
  BACKGROUND_IMAGE_FILE: require('../../images/ohiostate/banner2.png'),
  WHITE_LABEL_TEAM_ID: 181,
  PHONE_NUMBER: '866-774-6253',
  EMAIL: 'tickets@tucsonroadrunners.com',
  V2_FLEX_URL: '',
  banner: {
    choose: 'EVENT SELECTION',
    desc: 'Redeem your account credit for upcoming events. '
  },

  BANNER_TEXT: {
    home: {
      title: 'OHIO STATE CREDIT BANK',
      subtitle: 'Redeem credits for the upcoming season'
    },
    thankYou: {
      title: 'THANK YOU!',
      subtitle: "We're excited to have you with us this season."
    }
  },

  BANNER_OVERLAY: 0.8,
  THANK_YOU_IMAGE: require('../../images/ohiostate/banner.png'),

  announcementText:
    'New credit will be available to use within the portal in up to one hour. Utilized credit will take up to 15 minutes to deduct in the portal after games are selected.',

  EXTRA_NAV_LINKS: [],

  steps: [
    {
      sl: 1,
      title: 'LOGIN TO ACCOUNT MANAGER',
      getSubtitle: teamName =>
        `Connect your ${teamName} account using our secure Ticketmaster login.`,
      image: LOGIN_IMAGE,
      startButton: false
    },

    {
      sl: 2,
      title: 'SELECT YOUR GAMES',
      getSubtitle: teamName =>
        `Select the games you wish to attend based on the amount of credit you have available.`,
      image: SELECT_GAMES_IMAGE,
      startButton: false
    },

    {
      sl: 3,
      title: 'GET YOUR TICKETS',
      getSubtitle: teamName =>
        `Tickets will be available in your Account Manager within 24 hours of redemption. Reach out to Live Fan Support for any questions.`,
      image: GET_TICKETS_IMAGE,
      startButton: true
    }
  ],

  faqs: [
    {
      question: 'How does the Roadrunners Credit Allocation Work?',
      answer:
        'The CCA allows fans to allocate game credits to specific events from the upcoming schedule. Simply log in to your Roadrunners Team Account and select games from your available account credit.'
    },
    {
      question: 'Do I have to select all my games now?',
      answer:
        'No. You have the opportunity to allocate credit throughout the season. However, please note that prices are subject to change.'
    },
    {
      question: 'Can I choose seats throughout the venue?',
      answer:
        'You can only apply credits to games for the seats that are on your account. If you would like to move seats please contact your team account representative.'
    },
    {
      question: 'Where are event tickets delivered?',
      answer:
        'Event tickets are delivered directly within your Roadrunners team account within 48 hours of selection.'
    },
    {
      question: 'Can I still forward and resell my tickets?',
      answer:
        'Yes. All account actions including forwarding and resale are available within your Roadrunners team account after games are selected.'
    }
  ]
};
