import React, { useState, useEffect } from 'react';
import { Grid, Box, Divider, Typography, Paper } from '@material-ui/core';
const CustomerBlock = ({ details }) => {
  return (
    <Paper style={{ height: '100%' }}>
      <Box p={3} pt={2} pb={2}>
        <Typography variant="h4" color="textPrimary">
          Customer details
        </Typography>
      </Box>
      <Divider />

      <Box>
        <Box display="flex" p={3} pt={2} pb={2}>
          <Box width="50%">
            <Typography variant="h5" color="textPrimary">
              Email address
            </Typography>
          </Box>
          <Box width="50%">
            <Typography variant="h5" color="textSecondary">
              {details.emailAddress}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box display="flex" p={3} pt={2} pb={2}>
          <Box width="50%">
            <Typography variant="h5" color="textPrimary">
              Account number
            </Typography>
          </Box>
          <Box width="50%">
            <Typography variant="h5" color="textSecondary">
              {details.accountNumber}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box display="flex" p={3} pt={2} pb={2}>
          <Box width="50%">
            <Typography variant="h5" color="textPrimary">
              Team id
            </Typography>
          </Box>
          <Box width="50%">
            <Typography variant="h5" color="textSecondary">
              {details.teamId}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box display="flex" p={3} pt={2} pb={2}>
          <Box width="50%">
            <Typography variant="h5" color="textPrimary">
              Credit available
            </Typography>
          </Box>
          <Box width="50%">
            <Typography variant="h5" color="textSecondary">
              {'$ ' + Number(details.creditAvailable.$numberDecimal).toFixed(2)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default CustomerBlock;
