import React, { useEffect, useState } from 'react';
import {
  Paper,
  Typography,
  makeStyles,
  Box,
  Grid,
  colors,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import moment from 'moment';
import tConv24 from 'src/utils/utils';
import { ReportProblemOutlined } from '@material-ui/icons';
import clsx from 'clsx';

export default function Event({
  event,
  index,
  handleEventClick,
  activeEventIndex,
  eventCodeDetail
}) {
  const styles = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (eventCodeDetail == event.event_code) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [activeEventIndex]);

  return (
    <Box p={xs ? 1 : 2} className={styles.eventWrapper}>
      <Box className={styles.imageWrapper}>
        <img src={event['logo_url']} alt="" className={styles.eventImage} />
      </Box>

      <Box>
        <Box>
          <Typography
            variant="h6"
            color="textPrimary"
            className={styles.eventName}
          >
            {event.description}
          </Typography>
        </Box>

        <Box>
          <Typography variant="subtitle2" className={styles.eventMeta}>
            {moment(event.date).format('dddd MMMM DD, YYYY')}
          </Typography>
        </Box>

        <Box>
          <Typography variant="subtitle2" className={styles.eventMeta}>
            {tConv24(event.time)}
          </Typography>
        </Box>

        {!event.availableSeatCount == 0 && (
          <Box style={{ marginTop: 3 }}>
            <Typography variant="subtitle2" className={styles.warning}>
              <ReportProblemOutlined className={styles.warningIcon} /> Sold Out
            </Typography>
          </Box>
        )}

        {event.availableSeatCount > 0 && (
          <Box style={{ marginTop: 3 }}>
            <Typography variant="subtitle2" className={styles.success}>
              {event.availableSeatCount}{' '}
              {event.availableSeatCount > 1 ? 'Seats' : 'Seat'}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}

const useStyles = makeStyles(theme => ({
  eventOverlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)',
    zIndex: 999
  },

  eventWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'row',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  eventName: {
    fontFamily: 'Raleway',
    fontWeight: 700,
    fontSize: 16,
    lineHeight: 1.2,

    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  eventMeta: {
    fontFamily: 'Open Sans',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.4,
    color: colors.grey[600],
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },

  imageWrapper: {
    marginRight: 8,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0
    }
  },
  eventImage: {
    width: 48,
    marginTop: 4,
    [theme.breakpoints.down('sm')]: {
      width: 48
    }
  },

  warning: {
    color: theme.palette.warning.main,
    fontFamily: 'Open Sans',
    fontSize: 14,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  warningIcon: {
    marginRight: 1,
    marginBottom: -2,
    fontSize: 15
  },
  success: {
    color: theme.palette.success.main,
    fontFamily: 'Open Sans',
    fontSize: 14,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  }
}));
