import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  makeStyles,
  colors,
  useTheme,
  useMediaQuery,
  Drawer
} from '@material-ui/core';
import moment from 'moment';
import tConv24 from 'src/utils/utils';
import TopPanel from './TopPanel';
import SeatInfo from './SeatInfo';
import PaymentForm from './PaymentForm';
import BusinessInfo from './BusinessInfo';
import ActionButtons from './ActionButtons';
import WarningContent from './WarningContent';
import { LocalMallOutlined } from '@material-ui/icons';

export default function ConfirmOrderSidebar({
  open,
  handleConfirmDrawer,
  seat,
  customerCredit,
  activeSeatCountIndex,
  events,
  activeEventIndex,
  handleRegularPurchase
}) {
  const styles = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const [outstandingAmount, setOutstanding] = useState(0);
  const [hasOutstanding, setHasOutstanding] = useState(false);
  const [gameDate, setGameDate] = useState(null);
  const [gameTime, setGameTime] = useState(null);
  const [seatString, setSeatString] = useState('');
  const [gameImage, setGameImage] = useState('');
  const [remainingAmount, setRemainingAmount] = useState(0);

  const calculateOutstanding = () => {
    const accountBalance = customerCredit?.creditAvailable['$numberDecimal'];
    const totalCost = seat.price * (activeSeatCountIndex + 1);
    let outstandingAmount = Number(totalCost - accountBalance).toFixed(2);
    let haveOutstanding = outstandingAmount > 0;
    haveOutstanding && setHasOutstanding(true);
    setOutstanding(outstandingAmount);
  };

  const formatGameDateTime = () => {
    const date = events[activeEventIndex]['date'];
    const time = events[activeEventIndex]['time'];
    const gameDate = moment(date).format('dddd MMMM DD, YYYY');
    const gameTime = tConv24(time);
    setGameDate(gameDate);
    setGameTime(gameTime);
  };

  const formatSeatString = () => {
    const availableSeat = seat.available_seat;
    const start = parseInt(availableSeat.replaceAll(' ', '').split('-')[3]);
    const limit = activeSeatCountIndex + 1; // 1 for 0 base index, 1 for inclusive last one.
    const seats = Array(limit)
      .fill(0)
      .map((_, i) => start + i);
    const string =
      (seats.length > 1 ? 'Seats : ' : 'Seat : ') + seats.join(', ');
    setSeatString(string);
  };

  const findGameImage = () => {
    const url = events[activeEventIndex]['logo_url'];
    setGameImage(url);
  };

  const handlePurchaseConfirm = () => {
    !hasOutstanding && handleRegularPurchase(seat);
    return;
  };

  const calculateRemainingAmount = () => {
    const accountBalance = customerCredit?.creditAvailable['$numberDecimal'];
    const totalCost = seat.price * (activeSeatCountIndex + 1);
    const remainingAmount = Number(accountBalance - totalCost).toFixed(2);
    setRemainingAmount(remainingAmount);
  };

  useEffect(() => {
    calculateOutstanding();
    calculateRemainingAmount();
    formatGameDateTime();
    formatSeatString();
    findGameImage();
  }, []);

  // useEffect(calculateOutstanding, []);
  // useEffect(calculateRemainingAmount, []);
  // useEffect(formatGameDateTime, []);
  // useEffect(formatSeatString, []);
  // useEffect(findGameImage, []);

  return (
    <Drawer
      open={open}
      onClose={() => handleConfirmDrawer(false)}
      anchor={sm ? 'bottom' : 'right'}
      BackdropProps={{
        className: styles.drawerContainer
      }}
      PaperProps={{
        className: styles.drawerPaper
      }}
    >
      <TopPanel {...{ handleConfirmDrawer }} />

      <Box p={xs ? 3 : 4} className={styles.drawerContents}>
        <Box textAlign="center" mb={2} mt={2}>
          {!hasOutstanding && (
            <Typography variant="h4" className={styles.popupTitle}>
              <LocalMallOutlined className={styles.titleIcon} /> Confirm Order
            </Typography>
          )}
        </Box>

        <Box>
          <SeatInfo
            {...{
              seat,
              gameDate,
              gameTime,
              seatString,
              gameImage
            }}
          />
        </Box>

        <Box mt={2}>
          <BusinessInfo
            {...{
              seat,
              customerCredit,
              activeSeatCountIndex,
              outstandingAmount,
              hasOutstanding,
              remainingAmount
            }}
          />
        </Box>

        {hasOutstanding && (
          <Box mt={2}>
            <WarningContent />
          </Box>
        )}

        {/* {hasOutstanding && (
          <Box mt={2}>
            <PaymentForm {...{ outstandingAmount }} />
          </Box>
        )} */}

        {!hasOutstanding && (
          <Box mt={2}>
            <ActionButtons
              {...{
                handleConfirmDrawer,
                handlePurchaseConfirm
              }}
            />
          </Box>
        )}

        <Box pb={10} />
      </Box>
    </Drawer>
  );
}

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    [theme.breakpoints.down('sm')]: {
      borderRadius: 20,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      maxHeight: 750,
      overflow: 'hidden'
    },

    [theme.breakpoints.down('xs')]: {
      maxHeight: 650
    }
  },
  drawerContainer: {
    backdropFilter: 'blur(4px)'
  },

  drawerContents: {
    width: 350,
    height: 'fit-content',
    overflow: 'hidden',

    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      overflowY: 'scroll'
    }
  },

  popupTitle: {
    fontFamily: 'Raleway',
    fontSize: 20,
    color: colors.grey[800],
    fontWeight: 700
  },
  titleIcon: {
    fontSize: 24,
    marginBottom: -4
  }
}));
