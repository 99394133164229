import React from 'react';
import {
  Box,
  Typography,
  Button,
  makeStyles,
  Grid,
  colors
} from '@material-ui/core';
import { NotificationsActiveOutlined, InfoOutlined } from '@material-ui/icons';

export default function NoTransaction() {
  const styles = useStyles();

  return (
    <Box pt={8} pb={8} pl={2} pr={2}>
      <Grid container justify="center">
        <Grid item xs={12} sm={8} md={6} lg={4}>
          <Box textAlign="center" mt={2}>
            <Typography variant="h4" className={styles.sectionTitle}>
              Oops !
            </Typography>
          </Box>

          <Box textAlign="center" mt={2}>
            <Typography variant="h4" className={styles.sectionSubtitle}>
              No transaction history available.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

const useStyles = makeStyles(theme => ({
  sectionTitle: {
    fontFamily: 'Raleway',
    fontSize: 24,
    fontWeight: 700
  },

  infoIcon: {
    fontSize: 48,
    color: colors.grey[500]
  },
  sectionSubtitle: {
    fontFamily: 'Raleway',
    fontSize: 18,
    fontWeight: 600,
    color: colors.grey[600]
  }
}));
