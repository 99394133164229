import React from 'react';
import {
  Box,
  Paper,
  Typography,
  makeStyles,
  Container,
  colors,
  Grid,
  Avatar,
  useTheme,
  useMediaQuery,
  Button,
  CircularProgress
} from '@material-ui/core';
import {
  AccountBalanceOutlined,
  DoneAllRounded,
  EditOutlined
} from '@material-ui/icons';
import { Edit } from 'react-feather';

export default function BottomPanel({
  customerCredit,
  selectionSubmit,
  isSelectionSubmitted,
  apiRequestInProgress
}) {
  const styles = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const md = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Paper variant="outlined" className={styles.paper}>
      <Container maxWidth={false}>
        <Grid container justify="center">
          <Grid item xs={12} lg={10} xl={8}>
            <Box pt={2} pb={2}>
              {isSelectionSubmitted ? (
                <Box className={styles.submittedSectionWrapper}>
                  <Box mr={1}>
                    <Avatar variant="rounded" className={styles.avatar}>
                      <DoneAllRounded className={styles.checkIcon} />
                    </Avatar>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    width="100%"
                    justifyContent={md ? 'flex-start' : 'space-between'}
                    alignItems={md ? 'flex-start' : 'center'}
                    flexDirection={md ? 'column' : 'row'}
                  >
                    <Typography variant="h6" className={styles.submittedTitle}>
                      Submitted!
                    </Typography>

                    <Button
                      disableElevation
                      size={xs ? 'small' : 'large'}
                      variant={md ? 'text' : 'contained'}
                      color="primary"
                      startIcon={md ? '' : <EditOutlined />}
                      className={styles.editButton}
                      onClick={() => selectionSubmit(false)}
                    >
                      Edit Selection
                    </Button>
                  </Box>
                </Box>
              ) : (
                <Box className={styles.creditItemWrapper}>
                  <Box mr={1}>
                    <Avatar variant="rounded" className={styles.avatar}>
                      {!customerCredit.creditAvailable ? (
                        <CircularProgress size={20} color="primary" />
                      ) : (
                        <AccountBalanceOutlined
                          className={styles.creditItemIcon}
                        />
                      )}
                    </Avatar>
                  </Box>

                  <Box>
                    {customerCredit.creditAvailable && (
                      <>
                        <Typography variant="h6" className={styles.creditTitle}>
                          Credit Available
                        </Typography>
                        <Typography
                          variant="h2"
                          className={styles.creditAmount}
                        >
                          $
                          {Number(
                            customerCredit.creditAvailable['$numberDecimal']
                          )
                            .toFixed(2)
                            .toLocaleString()}
                        </Typography>
                      </>
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Paper>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    borderRadius: 0,
    borderBottom: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    boxShadow: '0 -2px 32px rgb(0 0 0 / 10%)',
    borderTop: `1px solid ${colors.grey[300]}`,
    zIndex: 10,
    height: 80,
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down('xs')]: {
      height: 70
    }
  },
  submittedSectionWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  creditItemWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.divider}`,

    [theme.breakpoints.down('xs')]: {
      width: 30,
      height: 30
    }
  },
  creditItemIcon: {
    fontSize: 28,
    color: colors.grey[700],

    [theme.breakpoints.down('xs')]: {
      fontSize: 20
    }
  },

  checkIcon: {
    fontSize: 28,
    color: theme.palette.success.main,

    [theme.breakpoints.down('xs')]: {
      fontSize: 20
    }
  },
  creditTitle: {
    fontFamily: 'Open Sans',
    fontWeight: 600,
    fontSize: 16,
    color: theme.palette.primary.main,
    letterSpacing: '0.05em',
    [theme.breakpoints.down('xs')]: {
      fontSize: 16
    }
  },
  submittedTitle: {
    fontFamily: 'Open Sans',
    fontWeight: 600,
    fontSize: 18,
    color: theme.palette.success.main,
    letterSpacing: '0.05em'
  },
  creditAmount: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 24,
    color: colors.grey[800],

    [theme.breakpoints.down('xs')]: {
      fontSize: 18
    }
  },
  editButton: {
    borderRadius: 25,

    '& .MuiButton-startIcon': {
      marginRight: 5
    },

    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      padding: '0',
      textDecoration: 'underline',
      fontWeight: 600,
      lineHeight: 1.6,

      '& .MuiButton-startIcon': {
        marginRight: 2,

        '& svg': {
          fontSize: '14px'
        }
      }
    }
  }
}));
