import React, { useState } from 'react';
import one from '../images/1.png';
import two from '../images/2.png';
import three from '../images/3.png';

import teamAuth from '../images/team-auth.png';
import selectGames from '../images/choose-games.png';
import getTickets from '../images/get-tickets.png';
import bluebadge from '../images/blue-badge.png';
import Accordion from '../components/Accordion';
import {
  siteTitle,
  THEME,
  faqs,
  logo,
  SUBDOMAIN,
  WHITE_LABEL_TEAM_ID,
  API_BASE_URL,
  teamName,
  BACKGROUND_IMAGE_FILE as heroImage,
  development,
  ACCOUNT_MANAGER_LINK,
  BANNER_TEXT,
  HERO_OVERLAY,
  LOGIN_IMAGE,
  SELECT_GAMES_IMAGE,
  GET_TICKETS_IMAGE,
  TM_LOGIN_URI,
  teamNameShort,
  testAccountNumber,
  announcementText,
  steps
} from '../views/data/environment';
import { Router, useHistory } from 'react-router-dom';
import { Box, useTheme, Container } from '@material-ui/core';
import axios from 'axios';

import './css/home.scss';
import Swal from 'sweetalert2';
import Axios from 'axios';
import Banner from '../components/Banner';
import Step from '../components/Step';
import { v4 as uuid } from 'uuid';

export default function Home() {
  const history = useHistory();
  const theme = useTheme();

  const [teamAccountUrl, setTeamAccountUrl] = useState('');
  const [isEnabled, setIsEnabled] = useState(null);

  // React.useEffect(() => {
  //   if (development == true) {
  //     return history.push({
  //       pathname: `/products`,
  //       state: {
  //         accountId: '16934269'
  //       }
  //     });
  //   }
  // }, [development]);

  React.useEffect(() => {
    toggleIntercom('block');
    Axios.post(`${API_BASE_URL}/configuration/enabled`).then(res => {
      setIsEnabled(res.data.isPortalOpen);
    });
  });

  const toggleIntercom = mode => {
    let checkElement = setInterval(function() {
      let intercom = document.getElementsByClassName('intercom-launcher');
      if (intercom.length) {
        intercom[0].style.display = mode;
        clearInterval(checkElement);
      }
    }, 100);
  };

  React.useEffect(() => {
    // get team account URL
    axios.get(`${API_BASE_URL}/teamAuth/url/${WHITE_LABEL_TEAM_ID}`).then(
      response => {
        if (development == true) {
          alert('DEV MODE!');
        }
        setTeamAccountUrl(response.data.url);
        const params = new URLSearchParams(history.location.search);
        const code = params.get('code');
        if (code) {
          const data = {
            teamId: WHITE_LABEL_TEAM_ID,
            authCode: code,
            redirectUri: `https://${SUBDOMAIN}.seasonshare.com/tmcallback/linkAccount`
          };
          axios
            .post(`${API_BASE_URL}/teamAuth/linkAccount`, data, {
              withCredentials: true
            })
            .then(
              response2 => {
                if (response2.data.accountNumber) {
                  // remove the code to prevent error on back
                  const queryParams = new URLSearchParams(
                    history.location.search
                  );
                  if (queryParams.has('code')) {
                    queryParams.delete('code');
                    history.replace({
                      search: queryParams.toString()
                    });
                  }
                  history.push({
                    pathname: `/products`,
                    state: {
                      accountId: response2.data.accountNumber
                    }
                  });
                } else {
                  Swal.fire(
                    'Foul!',
                    'Something strange happened. Please contact Live Fan Support.',
                    'error'
                  );

                  const slackURL =
                    'https://hooks.slack.com/services/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';
                  const options = {
                    url: slackURL,
                    json: true,
                    body: {
                      text: JSON.stringify(response2.data)
                    }
                  };
                  axios.post(slackURL, options).then(
                    res => {
                      console.log(res);
                    },
                    err => console.log(err)
                  );
                }
              },
              err => {
                if (development == true) {
                  return history.push({
                    pathname: `/products`,
                    state: {
                      accountId: testAccountNumber
                    }
                  });
                }

                err.response.status === 500 &&
                  Swal.fire(
                    'Oops..',
                    err.response?.data?.loginError
                      ? err.response.data.errorMessage
                      : `Looks like your not logged into your ${teamNameShort} Team Account. Please login <a class="login_uri"  href="${TM_LOGIN_URI}" target="_blank" >here</a> and try again.`,
                    'error'
                  ).then(res => {
                    if (res.isConfirmed) {
                      window.location.href = '/';
                    }
                  });
                err.response.status === 501 &&
                  Swal.fire(
                    'Oops..',
                    err.response.data.errorMessage ||
                      `Looks like your not logged into your ${teamNameShort} Team Account. Please login <a class="login_uri"  href="${TM_LOGIN_URI}" target="_blank" >here</a> and try again.`,
                    'error'
                  ).then(res => {
                    if (res.isConfirmed) {
                      window.location.href = '/';
                    }
                  });
              }
            );
        }
      },
      err => {
        console.log(err);
      }
    );
  }, []);

  const lookupAccount = () => {
    let replaced = teamAccountUrl.replace(
      'REDIRECT_URL',
      `https://${SUBDOMAIN}.seasonshare.com/tmcallback/linkAccount`
    ); // redirect back to where we came from, handle in params
    let finalUrl = replaced.replace('STATE', `UPLOAD-${WHITE_LABEL_TEAM_ID}`);
    window.location.href = `${finalUrl}`;
  };

  return (
    <div>
      {announcementText && (
        <div className="header-cont-2">
          <h4>{announcementText}</h4>
        </div>
      )}

      <Banner
        background={heroImage}
        title={BANNER_TEXT.home.title}
        subtitle={BANNER_TEXT.home.subtitle}
        isPortalOpen={isEnabled}
        {...{ teamAccountUrl }}
      />

      <Box
        className="header-cont"
        style={{ backgroundColor: theme.palette.primary.main }}
      >
        <h3>HOW IT WORKS</h3>
      </Box>

      <Container maxWidth={false}>
        {steps.map(step => (
          <Box>
            <Step
              index={step.sl}
              title={step.title}
              subtitle={step.getSubtitle(teamName)}
              image={step.image}
              button={
                step.startButton && (
                  <button
                    style={{ cursor: 'pointer', fontWeight: 'bold' }}
                    onClick={lookupAccount}
                    className="btn"
                  >
                    GET STARTED NOW
                  </button>
                )
              }
            />
          </Box>
        ))}
      </Container>

      <div className="flex-cont3">
        <h1 className="freq">FAQ</h1>
      </div>
      <div className="faq-container">
        {faqs.map((faq, index) => (
          <Accordion key={index} question={faq.question} answer={faq.answer} />
        ))}
      </div>
      <div className="whole-footer">
        <div className="footer">
          <div className="badge-cont">
            <img src={bluebadge} className="footer-badge" alt="logooovhvkj1" />
            <p className="foot-texts">Built for fans</p>
          </div>
          <div className="links-cont">
            <b>
              <p className="foot-texts">Privacy Policy</p>
            </b>
            <b>
              <p className="foot-texts">About us</p>
            </b>
            <b>
              <p className="foot-texts">Terms of services</p>
            </b>
            <b>
              <p className="foot-texts">Press</p>
            </b>
          </div>
        </div>
        <div className="copyright-cont">
          <p className="bottom-texts">Copyright 2020 Season Share Inc.</p>
        </div>
        <button
          style={{ cursor: 'pointer' }}
          onClick={() => {
            let replaced = teamAccountUrl.replace(
              'REDIRECT_URL',
              `https://${SUBDOMAIN}.seasonshare.com/tmcallback/linkAccount`
            ); // redirect back to where we came from, handle in params
            let finalUrl = replaced.replace(
              'STATE',
              `UPLOAD-${WHITE_LABEL_TEAM_ID}`
            );
            window.location.href = `${finalUrl}`;
          }}
          className="btn"
        >
          <b>CHECK YOUR CREDITS</b>
        </button>
      </div>
    </div>
  );
}
