import * as types from './actionTypes';
import initialState from '../initialState';

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ALL_CUSTOMERS:
      return { ...state, customers: action.payload };
    case types.GET_CUSTOMER_CREDITS:
      return { ...state, customerCredits: action.payload };
    case types.GET_CUSTOMER_TRANSACTIONS:
      return { ...state, customerTransactions: action.payload };
    case types.GET_CUSTOMER_HISTORY:
      return { ...state, customerHistory: action.payload };
    case types.GET_TRANSACTIONS:
      return { ...state, transactions: action.payload };
    case types.PURCHASED_TICKET:
      return { ...state, transactions: action.payload };
    case types.GET_ACTIVITY:
      return { ...state, activity: action.payload };
    default:
      return state;
  }
};

export default customerReducer;
