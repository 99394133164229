import * as types from './actionTypes';
import initialState from '../initialState';

const eventReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ALL_EVENTS:
      return { ...state, events: action.payload };
    case types.PREVIEW_EVENT:
      return { ...state, previewEvent: action.payload };
    case types.SAVE_EVENT:
      return {
        ...state,
        events: action.payload
      };
    case types.GET_SECTIONS:
      return {
        ...state,
        seatBlocks: action.payload
      };
    case types.LOADING_SEATS:
      state.seatsLoading = action.payload;
      return { ...state };
    default:
      return state;
  }
};

export default eventReducer;
