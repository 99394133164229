import React from 'react';
import {
  Box,
  Paper,
  Typography,
  makeStyles,
  colors,
  Grid,
  Divider,
  Hidden,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import {
  InfoOutlined,
  DescriptionRounded,
  ViewListRounded,
  MonetizationOnRounded
} from '@material-ui/icons';
import Transaction from './Transaction';
import { v4 as uuid } from 'uuid';
import clsx from 'clsx';
import { teamNameShort } from 'src/views/data/environment';
import AccountManagerButton from 'src/components/AccountManagerButton';

export default function TransactionsPanel({
  customerTransactions,
  customerTransactionHistory
}) {
  const styles = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const xs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Paper variant="outlined">
      <Box p={sm ? 2 : 4}>
        <Box textAlign="center">
          <Typography
            variant="h5"
            color="textPrimary"
            className={styles.sectionTitle}
          >
            Transactions
          </Typography>
        </Box>
        <Grid container justify="center">
          <Grid item xs={12} lg={6}>
            <Box mt={1} textAlign="center">
              <Typography variant="subtitle1" className={styles.infoText}>
                <InfoOutlined />
                Please allow up to 2 hours to process orders. Barcodes can be
                accessed from your {teamNameShort} Team Account.
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid container justify="center">
          <Grid item xs={12} sm={6} md={5} lg={5} xl={4}>
            <Box mt={3} textAlign="center">
              <AccountManagerButton label="Click here to access barcodes" />
            </Box>
          </Grid>
        </Grid>
        <Box mt={4}>
          <Paper variant="outlined" style={{ overflow: 'hidden' }}>
            <Box pl={2} pr={2} className={styles.transTitles}>
              <Box />
              <Box
                className={clsx(styles.titleWrapper, styles.firstTitleWrapper)}
              >
                <Typography variant="h6" className={styles.colTitle}>
                  <DescriptionRounded className={styles.titleIcon} />
                  Descriptions
                </Typography>
              </Box>
              <Box className={styles.titleWrapper}>
                <Typography variant="h6" className={styles.colTitle}>
                  <MonetizationOnRounded className={styles.titleIcon} />
                  Total Cost
                </Typography>
              </Box>
              <Box className={styles.titleWrapper}>
                <Typography variant="h6" className={styles.colTitle}>
                  <ViewListRounded className={styles.titleIcon} />
                  Status
                </Typography>
              </Box>
            </Box>

            <Box>
              {customerTransactions.map((transaction, index) => (
                <React.Fragment key={uuid()}>
                  <Transaction
                    {...{ transaction, customerTransactionHistory, index }}
                  />
                  {index !== customerTransactions.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </Box>
          </Paper>
        </Box>
      </Box>
    </Paper>
  );
}

const useStyles = makeStyles(theme => ({
  sectionTitle: {
    fontFamily: 'Raleway',
    fontSize: 24,
    fontWeight: 700
  },

  infoText: {
    fontFamily: 'Open Sans',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.2,
    color: theme.palette.success.main,

    '& .MuiSvgIcon-root': {
      marginBottom: -4,
      marginRight: 5,
      fontSize: 20
    }
  },

  transTitles: {
    display: 'grid',
    gridTemplateColumns: '60px 1fr 1fr 1fr',
    // boxShadow: '0 8px 16px rgb(0 0 0 / 10%)',
    zIndex: 10,
    position: 'relative',
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: colors.grey[50],
    paddingTop: 10,
    paddingBottom: 10,

    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },

  titleWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  firstTitleWrapper: {
    justifyContent: 'flex-start'
  },
  colTitle: {
    fontFamily: 'Poppins',
    fontSize: 14,
    fontWeight: 600,
    color: colors.grey[600],
    textTransform: 'uppercase'
  },
  titleIcon: {
    color: colors.grey[700],
    fontSize: 24,
    marginBottom: -6,
    marginRight: 5
  }
}));
