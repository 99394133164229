import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as eventActions from '../../../state/event/eventAction';
import { TableRow, TableCell, makeStyles } from '@material-ui/core';
import { Trash, Edit as EditIcon } from 'react-feather';
import EditEventModal from './EditEventModal';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  root: {},
  trash: {
    cursor: 'pointer'
  }
}));

function EventTableRow({ event }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const handleEditDialog = type => {
    if (type === 'open') setEditDialogOpen(true);
    if (type === 'close') setEditDialogOpen(false);
  };
  const eventDelete = eventId => {
    dispatch(eventActions.deleteEvent(eventId));
  };

  return (
    <>
      <TableRow hover>
        <TableCell>{event.event_code}</TableCell>
        <TableCell>{event.description}</TableCell>
        <TableCell>{moment(event.date).format('MM/DD/YYYY')}</TableCell>
        <TableCell>{event.time}</TableCell>
        <TableCell>
          <Trash
            className={classes.trash}
            size={18}
            onClick={() => {
              eventDelete(event.id);
            }}
          />
          <EditIcon
            size={18}
            className={classes.trash}
            style={{ marginLeft: 5 }}
            onClick={() => handleEditDialog('open')}
          />
        </TableCell>
      </TableRow>
      <EditEventModal
        open={editDialogOpen}
        handleDialog={handleEditDialog}
        event={event}
        key={event._id}
      />
    </>
  );
}

export default EventTableRow;
