import * as types from './actionTypes';
import initialState from '../initialState';

export default function apiCallStatusReducer(
  state = initialState.apiRequestInProgress,
  action
) {
  if (action.type == types.BEGIN_API_CALL) {
    return true;
  } else if (action.type === types.API_CALL_ERROR || action.type == types.END_API_CALL) {
    return false;
  }
  return state;
}
