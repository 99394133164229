import React from 'react';
import {
  Paper,
  Box,
  Grid,
  makeStyles,
  Typography,
  useTheme,
  useMediaQuery,
  colors
} from '@material-ui/core';

export default function Step({ index, title, subtitle, image, button }) {
  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const xs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Box
      pl={sm ? 2 : 4}
      pr={sm ? 2 : 4}
      pt={8}
      pb={8}
      style={{
        borderBottom: xs ? `1px solid ${colors.grey[400]}` : 'none'
      }}
    >
      <Grid
        container
        spacing={sm ? 4 : 6}
        alignItems="center"
        // direction={rtl ? 'row-reverse' : 'row'}
      >
        <Grid item xs={12} sm={7} lg={5}>
          <Box>
            <Box textAlign="center">
              <Box className={classes.index}>{index}</Box>
            </Box>
            <Box textAlign="center" mt={2}>
              <Typography
                className={classes.title}
                variant="h3"
                color="textPrimary"
              >
                {title}
              </Typography>
            </Box>
            <Box textAlign="center" mt={3}>
              <Typography
                className={classes.subtitle}
                variant="subtitle1"
                color="textSecondary"
              >
                {subtitle}
              </Typography>
            </Box>
            <Box mt={1}>
              <Box className={classes.buttonWrap} textAlign="center">
                {button}
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={5} lg={7}>
          <img src={image} className={classes.image} alt="" />
        </Grid>
      </Grid>
    </Box>
  );
}

const useStyles = makeStyles(theme => ({
  index: {
    width: 50,
    height: 50,
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 28,
    fontWeight: 600,
    fontFamily: 'Open Sans',
    margin: '0 auto'
  },
  title: {
    fontFamily: 'Raleway',
    fontWeight: 700,
    fontSize: 24
  },
  subtitle: {
    fontFamily: 'Raleway',
    fontWeight: 500,
    fontSize: 22,
    lineHeight: 1.2
  },

  image: {
    width: '100%',
    borderRadius: 10,
    // border: '3px solid gray',
    boxShadow: `0 1px 2px rgba(0,0,0,0.09), 
                0 2px 4px rgba(0,0,0,0.09), 
                0 4px 8px rgba(0,0,0,0.09), 
                0 8px 16px rgba(0,0,0,0.09),
                0 16px 32px rgba(0,0,0,0.09), 
                0 32px 64px rgba(0,0,0,0.09)`
  }
}));
