import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  TableRow,
  TableCell,
  Table,
  TableBody,
  Typography,
  Button,
  CircularProgress
} from '@material-ui/core';
import swal from 'sweetalert2';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import { WHITE_LABEL_TEAM_ID, API_BASE_URL } from '../data/environment';
import { getAllEventReminders } from '../../state/reminder/reminderAction';

function ReminderTableRow({ reminder }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleSendReminder = async () => {
    const endpoint = `${API_BASE_URL}/event/reminder/send`;
    setLoading(true);
    try {
      let response = await axios.post(endpoint, {
        teamId: WHITE_LABEL_TEAM_ID,
        eventCode: reminder.eventCode
      });
      if (response.status === 200) {
        await dispatch(getAllEventReminders());
        swal.fire({
          icon: 'success',
          title: 'Reminder sent successfully'
        });
      }
    } catch (error) {
      swal.fire({
        icon: 'error',
        title: 'Something went wrong, please try again'
      });
    }
    setLoading(false);
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <Typography variant="subtitle2" color="textSecondary">
            {reminder && reminder.eventCode ? reminder.eventCode : ''}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle2" color="textSecondary">
            {reminder && reminder.description ? reminder.description : ''}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle2" color="textSecondary">
            {reminder && reminder.subscriberCount
              ? reminder.subscriberCount
              : ''}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle2" color="textSecondary">
            {reminder && reminder.lastSent ? reminder.lastSent : 'unavailable'}
          </Typography>
        </TableCell>
        <TableCell>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={handleSendReminder}
            disabled={loading}
          >
            Send reminder
            {loading && (
              <CircularProgress
                size={12}
                style={{ marginLeft: 5, color: 'gray' }}
              />
            )}
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
}
export default ReminderTableRow;
