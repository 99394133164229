import React, { useState } from 'react';
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: { marginTop: 15 },
  avatar: {
    marginRight: theme.spacing(2)
  },
  box: {
    minWidth: 1050,
    overflow: 'auto'
  },
  mBottom: {
    marginBottom: 0
  },
  textCenter: {
    textAlign: 'center',
    padding: 20
  },
  cell_long: {
    minWidth: 140
  },
  cell_long_width: {
    minWidth: 90
  }
}));

const ActivityDetails = ({ activities }) => {
  const classes = useStyles();
  const [selectedActivityIds, setSelectedActivityIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const handleSelectAll = event => {
    let newSelectedActivityIds;

    if (event.target.checked) {
      newSelectedActivityIds = activities.map(activity => activity._id);
    } else {
      newSelectedActivityIds = [];
    }

    setSelectedActivityIds(newSelectedActivityIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedActivityIds.indexOf(id);
    let newSelectedActivityIds = [];

    if (selectedIndex === -1) {
      newSelectedActivityIds = newSelectedActivityIds.concat(
        selectedActivityIds,
        id
      );
    } else if (selectedIndex === 0) {
      newSelectedActivityIds = newSelectedActivityIds.concat(
        selectedActivityIds.slice(1)
      );
    } else if (selectedIndex === selectedActivityIds.length - 1) {
      newSelectedActivityIds = newSelectedActivityIds.concat(
        selectedActivityIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedActivityIds = newSelectedActivityIds.concat(
        selectedActivityIds.slice(0, selectedIndex),
        selectedActivityIds.slice(selectedIndex + 1)
      );
    }

    setSelectedActivityIds(newSelectedActivityIds);
  };

  const handleLimitChange = event => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Card className={classes.root}>
      <CardHeader title="Activity" />
      <Divider />
      {activities && activities.length > 0 ? (
        <>
          <Box className={classes.box}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedActivityIds.length === activities.length}
                      color="primary"
                      indeterminate={
                        selectedActivityIds.length > 0 &&
                        selectedActivityIds.length < activities.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell className={classes.cell_long}>Type</TableCell>
                  <TableCell className={classes.cell_long_width}>
                    Detail
                  </TableCell>
                  <TableCell className={classes.cell_long_width}>
                    Account Id
                  </TableCell>
                  <TableCell className={classes.cell_long}>Date</TableCell>
                  <TableCell>Input</TableCell>
                  <TableCell>Output</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {activities.slice(0, limit).map(activity => (
                  <TableRow
                    hover
                    key={activity._id}
                    selected={selectedActivityIds.indexOf(activity._id) !== -1}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={
                          selectedActivityIds.indexOf(activity._id) !== -1
                        }
                        onChange={event => handleSelectOne(event, activity._id)}
                        value="true"
                      />
                    </TableCell>
                    <TableCell className={classes.cell_long}>
                      {activity.type}
                    </TableCell>
                    <TableCell className={classes.cell_long_width}>
                      {activity.detail}
                    </TableCell>
                    <TableCell>{activity.accountId}</TableCell>
                    <TableCell className={classes.cell_long}>
                      {moment(activity.date).format('MM/DD/YYYY hh:mm A')}
                    </TableCell>
                    <TableCell>{activity.input}</TableCell>
                    <TableCell>{activity.output}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
          <TablePagination
            component="div"
            count={activities.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </>
      ) : (
        <Box>
          <Typography className={classes.textCenter} color="textPrimary">
            No Data Found
          </Typography>
        </Box>
      )}
    </Card>
  );
};

export default ActivityDetails;
