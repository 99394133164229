const LOGIN_IMAGE = require('../../images/jaguars/login.png');
const SELECT_GAMES_IMAGE = require('../../images/jaguars/select_games.png');
const GET_TICKETS_IMAGE = require('../../images/jaguars/get_tickets.png');

export default {
  THEME: 'primary',
  THEME_NAME: 'jaguars',
  logo:
    'https://s3.us-east-2.amazonaws.com/ss-leagues/NFL/jacksonville-jaguars.png',
  seatMap: 'https://ss-stadiums.s3-us-west-1.amazonaws.com/tucson-seat-map.png',
  siteTitle: 'JACKSONVILLE JAGUARS',
  teamName: 'Jacksonville Jaguars',
  teamNameShort: 'Jaguars',
  testAccountNumber: '31383182',
  development: false,
  SUBDOMAIN: 'jagsflex',
  ACCOUNT_MANAGER_LINK: 'https://am.ticketmaster.com/jaguars/',
  API_BASE_URL: 'https://credit-backend-jags-joami.ondigitalocean.app',
  TM_LOGIN_URI: 'https://am.ticketmaster.com/jaguars/',
  API_BASE_URL: 'https://credit-backend-jags-joami.ondigitalocean.app',
  // API_BASE_URL: 'http://localhost:3001',
  IMAGE_BASE_URL: 'https://s3.us-east-2.amazonaws.com/',
  BACKGROUND_IMAGE_NAME: 'banner2.png',
  BACKGROUND_IMAGE_FILE: require('../../images/jaguars/banner2.png'),
  WHITE_LABEL_TEAM_ID: 97,
  PHONE_NUMBER: '904-633-2000',
  EMAIL: 'ticketing@jaguars.com',
  V2_FLEX_URL: '',
  banner: {
    choose: 'EVENT SELECTION',
    desc: 'Redeem your account credit for upcoming events. '
  },

  BANNER_TEXT: {
    home: {
      // title: 'JACKSONVILLE JAGUARS CREDIT BANK',
      // subtitle: 'Redeem credits for the upcoming season'
      title: '',
      subtitle: ''
    },
    thankYou: {
      title: '',
      subtitle: "We're excited to have you with us this season."
    }
  },

  BANNER_OVERLAY: 0,
  THANK_YOU_IMAGE: require('../../images/jaguars/thankyou.png'),

  announcementText:
    'New credit will be available to use within the portal in up to one hour. Utilized credit will take up to 15 minutes to deduct in the portal after games are selected.',

  EXTRA_NAV_LINKS: [],

  steps: [
    {
      sl: 1,
      title: 'LOGIN TO ACCOUNT MANAGER',
      getSubtitle: teamName =>
        `Connect your ${teamName} account using our secure Ticketmaster login.`,
      image: LOGIN_IMAGE,
      startButton: false
    },

    {
      sl: 2,
      title: 'SELECT YOUR GAMES',
      getSubtitle: teamName =>
        `Select the games you wish to attend based on the amount of credit you have available.`,
      image: SELECT_GAMES_IMAGE,
      startButton: false
    },

    {
      sl: 3,
      title: 'GET YOUR TICKETS',
      getSubtitle: teamName =>
        `Tickets will be available in your Account Manager within 24 hours of redemption. Reach out to Live Fan Support for any questions.`,
      image: GET_TICKETS_IMAGE,
      startButton: true
    }
  ],

  faqs: [
    {
      question: 'How does the JagsFlex work?',
      answer:
        'The JagsFlex allows fans to allocate game credits to specific events from the upcoming schedule. Simply log in to your Jaguars Account Manager and select games from your available account credit.'
    },
    {
      question: 'How do I get more credit?',
      answer:
        'Reach out to your Jaguars Account Rep and inquire about the JagsFlex program.'
    },
    {
      question: 'Do I have to select all my games now?',
      answer:
        'No. You have the opportunity to allocate credit throughout the season. However, please note that prices are subject to change.'
    },
    {
      question: 'Can I choose seats throughout the venue?',
      answer: 'Yes, you can choose from all current available inventory.'
    },
    {
      question: 'Where are event tickets delivered?',
      answer:
        'Event tickets are delivered directly within your Jaguars Account Manager within 24 hours of selection.'
    },
    {
      question: 'Can I still forward and resell my tickets?',
      answer:
        'Only foward is allowed using your Jaguars Account Manager after games are selected.'
    },
    {
      question: 'Credit Balance Availability',
      answer:
        'New credit will be available to use within the portal in up to one hour. Utilized credit will take up to 15 minutes to deduct in the portal after games are selected. '
    }
  ]
};
