import { combineReducers } from 'redux';
import auth from './auth/authReducer';
import events from './event/eventReducer';
import customers from './customer/customerReducer';
import reminders from './reminder/reminderReducer';
import apiRequestInProgress from './apiStatus/apiStatusReducer';

const rootReducer = combineReducers({
  auth,
  events,
  customers,
  reminders,
  apiRequestInProgress
});

export default rootReducer;
