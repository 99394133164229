import * as types from './actionTypes';
import initialState from '../initialState';

const reminderReducer = (state = [], action) => {
  switch (action.type) {
    case types.GET_ALL_REMINDERS:
      state = action.payload;
      return [...state];
    default:
      return state;
  }
};

export default reminderReducer;
