import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { development, logo, siteTitle } from 'src/views/data/environment';
import { Button, Typography } from '@material-ui/core';
import './NavBar.scss';
import {
  AccessAlarm,
  List,
  MoneyOffRounded,
  MoneyOutlined,
  MoneyRounded,
  MoneySharp,
  MoneyTwoTone
} from '@material-ui/icons';
import { DollarSign } from 'react-feather';
import {
  Box,
  Paper,
  makeStyles,
  colors,
  useTheme,
  useMediaQuery,
  IconButton
} from '@material-ui/core';
import {
  AccountCircleOutlined,
  ExpandMoreOutlined,
  MenuOutlined
} from '@material-ui/icons';
import Sidebar from './Sidebar';
import MenuItems from './MenuItems';

interface INavBarProps {
  accountId: string;
}

export const NavBar = (props: INavBarProps) => {
  const styles = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const md = useMediaQuery(theme.breakpoints.down('md'));
  const { accountId } = props;
  const history = useHistory();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const handleSidebar = (open: boolean) => (event: any) => {
    setSidebarOpen(open);
  };

  return (
    <>
      <Paper className={styles.navBar}>
        <Box
          pt={1}
          pb={1}
          pl={xs ? 1 : 2}
          pr={xs ? 1 : 2}
          className={styles.navContentWrapper}
        >
          <Box className={styles.logoWrapper}>
            <img src={logo} className={styles.logoImage} alt="logo" />
          </Box>
          <Box ml={1}>
            <Typography variant="h4" className={styles.siteName}>
              {siteTitle}
              <br />
              {development && ' DEV MODE'}
            </Typography>
          </Box>
          <Box flexGrow={1} />

          {!md && (
            <Box>
              <MenuItems {...{ handleSidebar, accountId }} />
            </Box>
          )}

          {md && (
            <Box>
              <>
                <Button
                  onClick={handleSidebar(true)}
                  variant="text"
                  className={styles.toggleButton}
                  startIcon={<MenuOutlined className={styles.personIcon} />}
                  // endIcon={<ExpandMoreOutlined className={styles.arrowIcon} />}
                />
                <Sidebar open={sidebarOpen} {...{ handleSidebar, accountId }} />
              </>
            </Box>
          )}
        </Box>
      </Paper>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  navBar: {
    width: '100%',
    border: 'none',
    boxShadow: '0 2px 32px rgb(0 0 0 / 10%)',
    borderBottom: `1px solid ${colors.grey[300]}`,
    borderRadius: 0,
    display: 'flex',
    alignItems: 'center'
  },
  navContentWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  logoWrapper: {},
  logoImage: {
    maxWidth: 70,
    maxHeight: '100%',
    cursor: 'pointer',

    [theme.breakpoints.down('xs')]: {
      maxWidth: 40
    }
  },
  siteName: {
    fontFamily: 'Raleway',
    fontSize: 18,
    fontWeight: 700,
    color: colors.grey[800],

    [theme.breakpoints.down('xs')]: {
      fontSize: 16
    }
  },

  toggleButton: {
    '& .MuiButton-startIcon, & .MuiButton-endIcon': {
      margin: 0,
      '& svg': {
        color: theme.palette.primary.main
      }
    }
  },
  personIcon: {
    fontSize: '32px !important'
  },
  arrowIcon: {
    fontSize: '18px !important'
  }
}));
