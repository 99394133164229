import React, { useEffect, useState } from 'react';
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TablePagination,
  Typography,
  Box,
  Paper,
  Divider,
  makeStyles,
  Chip,
  Collapse,
  IconButton,
  Button
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import ReminderTableRow from './ReminderTableRow';

function RemindersTable({ reminders }) {
  return (
    <Paper>
      <Box p={2}>
        <Typography color="textPrimary" variant="h4">
          Event Reminders
        </Typography>
      </Box>
      <Divider />
      <PerfectScrollbar>
        <Box minWidth={550}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle2" color="textPrimary">
                    Event Code
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography variant="subtitle2" color="textPrimary">
                    Description
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" color="textPrimary">
                    Number of subscriber
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" color="textPrimary">
                    Last sent
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" color="textPrimary">
                    Action
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reminders.map(reminder => {
                return <ReminderTableRow key={uuid()} reminder={reminder} />;
              })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Paper>
  );
}

export default RemindersTable;
