import apiClient from './config/apiClient';
import { WHITE_LABEL_TEAM_ID } from '../views/data/environment';
import {
  eventSeatEndPoint,
  customerEndPoint,
  transactionEndPoint,
  customerHistoryEndpoint
} from './config/apiEndpoints';

export const customerApi = {
  getAllCustomer() {
    return apiClient.get(customerEndPoint).then(response => {
      return response;
    });
  },
  getCustomerCredit(accountId) {
    const accountData = {
      accountId: accountId,
      teamId: WHITE_LABEL_TEAM_ID
    };
    return apiClient
      .post(customerEndPoint + '/credit', accountData)
      .then(response => {
        return response;
      });
  },
  getCustomerTransactions() {
    return apiClient.get(transactionEndPoint).then(response => {
      return response;
    });
  },
  getCustomerHistory(accountNumber) {
    return apiClient
      .post(`${customerHistoryEndpoint}/${accountNumber}`)
      .then(response => {
        return response;
      });
  },
  getTransactions(customerId) {
    return apiClient
      .get(transactionEndPoint + '/' + customerId)
      .then(response => {
        return response;
      });
  },
  getActivity(customerId) {
    const data = {
      accountId: customerId
    };
    return apiClient
      .post(customerEndPoint + '/activity', data)
      .then(response => {
        return response;
      });
  },
  purchaseEventTicket(id) {
    const purchaseData = {
      teamId: WHITE_LABEL_TEAM_ID,
      transactionId: id
    };
    return apiClient
      .post(eventSeatEndPoint + '/purchase/ticketmaster', purchaseData)
      .then(response => {
        return response;
      });
  }
};
