import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import moment from 'moment';
import { CSVLink } from 'react-csv';

const useStyles = makeStyles(theme => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  buttonStyle: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    textDecoration: 'none',
    fontSize: '0.875rem',
    minWidth: '64px',
    paddingTop: '6px',
    paddingBottom: '6px',
    paddingRight: '15px',
    paddingLeft: '15px',
    fontWeight: 500,
    lineHeight: 1.75,
    borderRadius: '4px',
    letterSpacing: '0.02857em',
    textTransform: 'uppercase',
    textAlign: 'center'
  }
}));

const Toolbar = ({ className, customers, ...rest }) => {
  const classes = useStyles();
  let customerCSV = [];

  useEffect(() => {
    customers &&
      customers.map(customer => {
        customerCSV.push({
          emailAddress: customer.emailAddress,
          accountNumber: customer.accountNumber,
          creditAvailable: Number(
            customer.creditAvailable.$numberDecimal
          ).toFixed(2),
          createdAt: moment(customer.createdAt).format('MM/DD/YYYY hh:mm A'),
          lastLogin: moment(customer.lastLogin).format('MM/DD/YYYY hh:mm A'),
          _id: customer._id,
          teamId: customer.teamId,
          __v: customer.__v
        });
      });
  }, [customers]);

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box display="flex" justifyContent="flex-end">
        <CSVLink
          className={classes.buttonStyle}
          filename="Customer.csv"
          data={customerCSV}
        >
          Export
        </CSVLink>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
