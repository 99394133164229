import React, { useEffect } from 'react';
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  useTheme,
  useMediaQuery,
  ListItemIcon,
  colors
} from '@material-ui/core';
import {
  LocalMallOutlined,
  AccountCircleOutlined,
  ListAltOutlined
} from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { v4 as uuid } from 'uuid';
import { EXTRA_NAV_LINKS } from 'src/views/data/environment';

const items = [
  {
    name: 'Redeem Credit',
    icon: <LocalMallOutlined />,
    url: '/products'
  },
  {
    name: 'Transaction History',
    icon: <ListAltOutlined />,
    url: '/summary'
  },
  {
    name: 'Logout',
    icon: <AccountCircleOutlined />,
    url: '/thankyou'
  }
];

export default function MenuItems({ handleSidebar, accountId }) {
  const styles = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down('md'));
  const handleClick = item => {
    if (item.url == '/logout') {
      history.replace('/thankyou');
      return;
    }

    history.push({
      pathname: item.url,
      state: {
        accountId
      }
    });

    md && handleSidebar(false);
  };

  const handleForward = item => {
    window.open(item.url, '_blank');
  };

  return (
    <List dense className={styles.listWrapper}>
      {EXTRA_NAV_LINKS.length > 0 &&
        EXTRA_NAV_LINKS.map(item => (
          <ListItem
            key={uuid()}
            button
            className={clsx(styles.listItem)}
            onClick={() => handleForward(item)}
          >
            <ListItemIcon className={styles.itemIcon}>{item.icon}</ListItemIcon>
            <ListItemText className={styles.itemText} primary={item.label} />
          </ListItem>
        ))}
      {items.map(item => (
        <ListItem
          key={uuid()}
          button
          className={clsx(
            styles.listItem,
            history.location.pathname.includes(item.url)
              ? styles.itemActive
              : ''
          )}
          onClick={() => handleClick(item)}
        >
          <ListItemIcon className={styles.itemIcon}>{item.icon}</ListItemIcon>
          <ListItemText className={styles.itemText} primary={item.name} />
        </ListItem>
      ))}
    </List>
  );
}

const useStyles = makeStyles(theme => ({
  listWrapper: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      display: 'block'
    }
  },
  listItem: {
    width: 'fit-content',
    borderRadius: 3,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      paddingTop: 8,
      paddingBottom: 8,
      paddingLeft: 8,
      paddingRight: 4
    }
  },
  itemIcon: {
    minWidth: 'unset',
    marginRight: 5,
    color: colors.grey[800],

    '& svg': {
      fontSize: 22
    }
  },
  itemText: {
    '& .MuiListItemText-primary': {
      fontFamily: 'Poppins',
      color: colors.grey[600],
      fontWeight: 400,
      textTransform: 'uppercase',
      fontSize: 14
    }
  },

  itemActive: {
    pointerEvents: 'none',
    '& .MuiListItemText-primary': {
      color: theme.palette.primary.main
    },

    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.main
    }
  }
}));
