import React, { useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  Grid,
  Button,
  makeStyles,
  CircularProgress,
  useTheme,
  useMediaQuery,
  colors,
  Divider
} from '@material-ui/core';
import Seat from './Seat';
import {
  InfoOutlined,
  ViewQuiltRounded,
  VerticalSplitRounded
} from '@material-ui/icons';
import NoSeats from './NoSeats';
import { v4 as uuid } from 'uuid';
import SectionSelector from './SectionSelector';
import { useSelector } from 'react-redux';

export default function SeatsPanel({
  seatState,
  handleLocationClick,
  getSeatNumString,
  activeSeatCountIndex,
  selectedSection,
  isEnabled,
  events,
  handleSubscribeClick,
  apiRequestInProgress,
  seatBlocks,
  customerCredit,
  activeEventIndex,
  handleRegularPurchase
}) {
  const styles = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const { seatsLoading } = useSelector(({ events }) => events);

  return (
    <Paper variant="outlined">
      {seatsLoading && (
        <Box p={12} textAlign="center">
          <CircularProgress size={24} />
        </Box>
      )}
      {!seatsLoading && (
        <>
          {seatBlocks.length < 1 ? (
            <NoSeats
              {...{
                seatBlocks,
                activeSeatCountIndex,
                handleSubscribeClick
              }}
            />
          ) : (
            <Box>
              <Box p={xs ? 2 : 3}>
                <SectionHead {...{ styles }} />
                <SelectionInfo
                  {...{ xs, styles, activeSeatCountIndex, selectedSection }}
                />
              </Box>
              <Box p={xs ? 2 : 3}>
                <Grid container spacing={2}>
                  {seatState.map((seat, index) => (
                    <Grid item xs={12} sm={6} lg={4} key={uuid()}>
                      <Seat
                        {...{
                          seat,
                          handleLocationClick,
                          getSeatNumString,
                          activeSeatCountIndex,
                          customerCredit,
                          events,
                          activeEventIndex,
                          handleRegularPurchase,
                          handleLocationClick
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
          )}
        </>
      )}
    </Paper>
  );
}

const SectionHead = ({ styles }) => (
  <Box>
    <Box textAlign="center">
      <Typography
        variant="h5"
        color="textPrimary"
        className={styles.sectionTitle}
      >
        Select Seats
      </Typography>
    </Box>

    <Box mt={1} textAlign="center">
      <Typography variant="subtitle1" className={styles.infoText}>
        <InfoOutlined />
        Tap to select.
      </Typography>
    </Box>
  </Box>
);

const SelectionInfo = ({
  xs,
  styles,
  activeSeatCountIndex,
  selectedSection
}) => (
  <Box mt={1} textAlign="center" display="flex" justifyContent="center">
    <Paper variant="outlined" className={styles.metaPaper}>
      <Box className={styles.metaWrapper}>
        <Typography
          variant="subtitle1"
          className={styles.metaText}
          style={{ marginBottom: xs ? 5 : 0 }}
        >
          <VerticalSplitRounded className={styles.metaIcon} />
          Seat Qty :{' '}
          <span className={styles.metaValue}>{activeSeatCountIndex + 1}</span>
        </Typography>
        <span className={styles.divider} />
        <Typography variant="subtitle1" className={styles.metaText}>
          <ViewQuiltRounded className={styles.metaIcon} />
          Section : <span className={styles.metaValue}>{selectedSection}</span>
        </Typography>
      </Box>
    </Paper>
  </Box>
);

const useStyles = makeStyles(theme => ({
  sectionTitle: {
    fontFamily: 'Raleway',
    fontSize: 24,
    fontWeight: 700
  },

  infoText: {
    fontFamily: 'Open Sans',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.2,
    color: theme.palette.success.main,

    '& .MuiSvgIcon-root': {
      marginBottom: -4,
      marginRight: 5,
      fontSize: 20
    }
  },

  metaPaper: {
    width: 'fit-content',
    backgroundColor: colors.grey[200],
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },

  metaWrapper: {
    padding: '8px 16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  metaText: {
    fontFamily: 'Open Sans',
    fontWeight: 500,
    fontSize: 16,
    color: colors.grey[600],
    pointerEvents: 'none',
    lineHeight: 1.2
  },
  metaValue: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 16,
    textTransform: 'capitalize'
  },
  metaIcon: {
    fontSize: 24,
    marginRight: 4,
    marginBottom: -7,
    color: colors.grey[600]
  },

  divider: {
    height: 20,
    marginLeft: 10,
    marginRight: 10,
    width: 2,
    backgroundColor: theme.palette.divider,
    display: 'inline-block',
    marginBottom: -3,
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  }
}));
