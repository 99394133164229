import React, { useState, useEffect } from 'react';
import {
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  makeStyles,
  IconButton,
  ClickAwayListener,
  Box,
  colors,
  CircularProgress
} from '@material-ui/core';
import { MoreVert, CloseRounded } from '@material-ui/icons';
import Axios from 'axios';
import { WHITE_LABEL_TEAM_ID, API_BASE_URL } from '../../data/environment';
import Swal from 'sweetalert2';

export default function ActionMenu({ customer }) {
  const styles = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef(null);
  const [loading, setLoading] = useState(false);

  const handleToggle = event => {
    event.stopPropagation();
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    event.stopPropagation();
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const purchaseEventTicket = async () => {
    setLoading(true);
    await Axios.post(`${API_BASE_URL}/inventory/purchase/ticketmaster`, {
      teamId: WHITE_LABEL_TEAM_ID,
      transactionId: customer._id
    })
      .then(response => {
        console.log('Purchase response: ', response);
        Swal.fire(
          'SUCCESS!',
          `<p>Successfully redeemed account credit! </p>`,
          'success'
        );
      })
      .catch(err => {
        Swal.fire(
          'Error!',
          `<p>${err.response.data.errorMessage} </p>`,
          'error'
        );
      });
    setLoading(false);
  };

  return (
    <>
      <IconButton
        size="medium"
        className={styles.iconButton}
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        {loading ? (
          <CircularProgress size={18} color="primary" />
        ) : open ? (
          <CloseRounded />
        ) : (
          <MoreVert />
        )}
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{
          zIndex: 99,
          marginLeft: -140
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'right top' : 'right bottom'
            }}
          >
            <Paper elevation={8} className={styles.paper}>
              <ClickAwayListener onClickAway={handleClose}>
                <Box p={1}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                    dense
                  >
                    <MenuItem
                      onClick={event => {
                        purchaseEventTicket();
                        handleClose(event);
                      }}
                      className={styles.menuItem}
                    >
                      SELL ONTO ACCOUNT
                    </MenuItem>
                  </MenuList>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  iconButton: {
    border: `1px solid ${theme.palette.divider}`
  },
  paper: {
    zIndex: 99,
    border: `1px solid ${theme.palette.divider}`,
    boxShadow: '0 8px 16px rgb(0 0 0 / 10%)'
  },
  menuItem: {
    color: colors.grey[600],
    borderRadius: 5,
    backgroundColor: colors.grey[200],
    fontSize: 16,
    fontWeight: 500,
    paddingTop: 10,
    paddingBottom: 10,
    marginTop: 8,
    marginBottom: 8
  }
}));
