import React, { useRef, useEffect, useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  makeStyles,
  colors,
  Grid,
  Button,
  Select,
  FormControl,
  MenuItem,
  Avatar,
  IconButton,
  CircularProgress,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import clsx from 'clsx';
import {
  InfoOutlined,
  ExpandMoreOutlined,
  EditOutlined,
  ViewQuiltOutlined,
  ViewQuiltRounded,
  TouchAppRounded
} from '@material-ui/icons';
import { v4 as uuid } from 'uuid';
import { useSelector } from 'react-redux';

export default function SectionSelector({
  selectedSection,
  handleSortSeat,
  sections
}) {
  const styles = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const [selectOpen, setSelectOpen] = useState(false);
  const handleSelect = open => setSelectOpen(open);
  const { seatsLoading } = useSelector(({ events }) => events);

  return (
    <Paper className={styles.paper} variant="outlined">
      <Box p={2} pb={0}>
        <Box textAlign="center">
          <Typography
            variant="h5"
            color="textPrimary"
            className={styles.sectionTitle}
          >
            Where do you want to sit?
          </Typography>
        </Box>

        <Box mt={1} textAlign="center">
          <Typography variant="subtitle1" className={styles.infoText}>
            <InfoOutlined />
            Select section from dropdown.
          </Typography>
        </Box>
      </Box>
      <Box p={xs ? 2 : 5} pt={3} textAlign="center">
        <Box>
          <FormControl size="small" fullWidth>
            <Select
              open={selectOpen}
              onOpen={() => handleSelect(true)}
              onClose={() => handleSelect(false)}
              variant="outlined"
              IconComponent={ExpandMoreOutlined}
              value={selectedSection}
              onChange={handleSortSeat}
              disabled={seatsLoading}
              startAdornment={
                seatsLoading ? (
                  <CircularProgress
                    className={styles.selectSpinner}
                    size={18}
                  />
                ) : (
                  <TouchAppRounded className={styles.selectIcon} />
                )
              }
              MenuProps={{
                classes: { paper: styles.selectMenu }
              }}
              classes={{
                root: styles.selectRoot
              }}
            >
              <MenuItem
                classes={{
                  root: styles.menuItemRoot,
                  selected: styles.menuItemSelected
                }}
                value="all"
              >
                All Sections
              </MenuItem>
              {sections.map(section => (
                <MenuItem
                  value={section}
                  key={uuid()}
                  classes={{
                    root: styles.menuItemRoot,
                    selected: styles.menuItemSelected
                  }}
                >
                  Section {section}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box mt={2}>
          <Paper variant="outlined">
            <Box p={2} className={styles.sectionViewer}>
              <Box>
                <Avatar
                  variant="rounded"
                  className={styles.sectionViewerAvatar}
                >
                  <ViewQuiltRounded className={styles.sectionViewerIcon} />
                </Avatar>
              </Box>

              <Box ml={2}>
                <Box>
                  <Typography
                    variant="h6"
                    color="textPrimary"
                    className={styles.sectionViewerTitle}
                  >
                    Selected Section
                  </Typography>
                </Box>

                <Box textAlign="left">
                  <Typography
                    variant="subtitle1"
                    color="textPrimary"
                    className={styles.sectionViewerName}
                  >
                    {selectedSection == 'all'
                      ? 'All Sections'
                      : `Section ${selectedSection}`}
                  </Typography>
                </Box>
              </Box>
              <Box flexGrow={1} />
              <Box>
                <IconButton
                  size="small"
                  className={styles.sectionViewerButton}
                  onClick={() => handleSelect(true)}
                >
                  <EditOutlined />
                </IconButton>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>
    </Paper>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    height: '100%'
  },
  sectionTitle: {
    fontFamily: 'Raleway',
    fontSize: 24,
    fontWeight: 700
  },

  infoText: {
    fontFamily: 'Open Sans',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.2,
    color: theme.palette.success.main,

    '& .MuiSvgIcon-root': {
      marginBottom: -4,
      marginRight: 5,
      fontSize: 20
    }
  },
  buttonGroup: {
    boxShadow: '0 8px 16px rgb(0 0 0 / 10%)'
  },

  selectRoot: {
    fontFamily: 'Open Sans',
    fontSize: 18,
    fontWeight: 500,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: 25,
    textTransform: 'uppercase'
  },

  selectSpinner: {
    height: 'auto !important'
  },
  selectIcon: {
    color: colors.grey[600]
  },

  selectMenu: {
    padding: '15px 30px 15px 20px'
  },

  menuItemRoot: {
    fontFamily: 'Open Sans',
    fontSize: 16,
    borderRadius: 5,
    textTransform: 'uppercase',
    fontWeight: 500,
    backgroundColor: colors.grey[50],
    marginTop: 15,
    color: colors.grey[800],
    backgroundColor: colors.grey[200],

    '&:hover': {
      backgroundColor: colors.grey[500],
      color: 'white'
    }
  },

  menuItemSelected: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: theme.palette.getContrastText(theme.palette.primary.main)
  },

  sectionViewer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    transition: 'all 0.2s ease',
    '&:hover': {
      boxShadow: '0 8px 16px rgb(0 0 0 / 10%)'
    }
  },

  sectionViewerTitle: {
    fontFamily: 'Raleway',
    fontWeight: 700,
    fontSize: 18,
    lineHeight: 1.2
  },

  sectionViewerName: {
    fontFamily: 'Open Sans',
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: 16,
    lineHeight: 1.2,
    marginTop: 4
  },

  sectionViewerAvatar: {
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.divider}`
  },

  sectionViewerIcon: {
    fontSize: 32,
    color: colors.grey[600]
  },
  sectionViewerButton: {
    width: 40,
    height: 40,
    border: `1px solid ${colors.grey[500]}`,

    '&:hover': {
      backgroundColor: colors.grey[600],
      color: 'white',
      border: `1px solid ${colors.grey[600]}`
    }
  }
}));
