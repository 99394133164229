import React from 'react';
const GET_STARTED_IMAGE = require('../../images/newjersey/get_started.png');
const LOGIN_IMAGE = require('../../images/newjersey/login.png');
const SELECT_GAMES_IMAGE = require('../../images/newjersey/select_games.png');
const GET_TICKETS_IMAGE = require('../../images/newjersey/get_tickets.png');

export default {
  THEME: 'primary',
  THEME_NAME: 'newjersey',
  logo: 'https://ss-leagues.s3.amazonaws.com/NHL/NewJerseyDevils-aug21.png',
  seatMap: 'https://ss-leagues.s3.amazonaws.com/NHL/Devils-SeatMap.png',
  siteTitle: 'NEW JERSEY DEVILS',
  teamName: 'New Jersey Devils',
  teamNameShort: 'New Jersey',
  testAccountNumber: '3953352',
  development: false,
  SUBDOMAIN: 'devils',
  ACCOUNT_MANAGER_LINK: 'https://am.ticketmaster.com/devils/',
  API_BASE_URL: 'https://devilscredit.seasonshare.com',
  TM_LOGIN_URI: 'https://am.ticketmaster.com/devils/',
  // API_BASE_URL: 'http://localhost:3001',
  IMAGE_BASE_URL: 'https://ss-leagues.s3.amazonaws.com/NHL/',
  BACKGROUND_IMAGE_NAME: 'banner3.jpg',
  BACKGROUND_IMAGE_FILE: require('../../images/newjersey/banner3.jpg'),
  WHITE_LABEL_TEAM_ID: 132,
  PHONE_NUMBER: '1-800-653-3845',
  EMAIL: 'clientexperience@newjerseydevils.com',
  V2_FLEX_URL: 'http://flex.seasonshare.com/devils/landing',
  banner: {
    choose: 'EVENT SELECTION',
    desc: 'Redeem your account credit for upcoming events. '
  },

  BANNER_TEXT: {
    home: {
      title: '',
      subtitle: ''
    },
    thankYou: {
      title: 'THANK YOU!',
      subtitle: "We're excited to have you with us this season."
    }
  },

  BANNER_OVERLAY: 0,
  THANK_YOU_IMAGE: require('../../images/newjersey/banner3.jpg'),

  announcementText:
    'Contact your Devils Account Manager to add credit. New credit will be available to use within the portal in up to one hour. Utilized credit will take up to 15 minutes to deduct in the portal after games are selected.',

  EXTRA_NAV_LINKS: [],

  steps: [
    {
      sl: 1,
      title: 'CLICK GET STARTED',
      getSubtitle: teamName => (
        <>
          Click <b>GET STARTED</b> on your desktop, laptop, or mobile device to
          begin.
        </>
      ),
      image: GET_STARTED_IMAGE,
      startButton: false
    },
    {
      sl: 2,
      title: 'LOGIN TO ACCOUNT MANAGER',
      getSubtitle: teamName =>
        `Connect your ${teamName} account using our secure Ticketmaster login.`,
      image: LOGIN_IMAGE,
      startButton: false
    },

    {
      sl: 3,
      title: 'SELECT YOUR GAMES',
      getSubtitle: teamName =>
        `Select the games you wish to attend based on the amount of credit you have available.`,
      image: SELECT_GAMES_IMAGE,
      startButton: false
    },

    {
      sl: 4,
      title: 'GET YOUR TICKETS',
      getSubtitle: teamName =>
        `Tickets will be available in your Account Manager within 24 hours of redemption. Reach out to Live Fan Support for any questions.`,
      image: GET_TICKETS_IMAGE,
      startButton: true
    }
  ],

  faqs: [
    {
      question: 'How does the New Jersey credit work?',
      answer:
        'The New Jersey credit allows fans to allocate game credits to specific events from the upcoming schedule. Simply log in to your New Jersey Account Manager and select games from your available account credit.'
    },
    {
      question: 'How do I get more credit?',
      answer:
        'Reach out to your New Jersey Account Rep and inquire about the New Jersey credit program.'
    },
    {
      question: 'Do I have to select all my games now?',
      answer:
        'No. You have the opportunity to allocate credit throughout the season. However, please note that prices are subject to change.'
    },
    {
      question: 'Can I choose seats throughout the venue?',
      answer: 'Yes, you can choose from all current available inventory.'
    },
    {
      question: 'Where are event tickets delivered?',
      answer:
        'Event tickets are delivered directly within your New Jersey Account Manager within 24 hours of selection.'
    },
    {
      question: 'Can I still forward and resell my tickets?',
      answer:
        'Only forward is allowed using your New Jersey Account Manager after games are selected.'
    },
    {
      question: 'Credit Balance Availability',
      answer:
        'New credit will be available to use within the portal in up to one hour. Utilized credit will take up to 15 minutes to deduct in the portal after games are selected. '
    }
  ]
};
